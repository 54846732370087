import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { UserItem } from './UserItem';
import { StateWrapper } from '../../../../components/StateWrapper';
import {
  useDeleteUserMutation,
  useFetchUsersQuery
} from '../../../../services/UserManagementService';
import CustomNoRowsOverlay from '../../../../components/NoData/NoData';
import { User } from '../../../../types/user';
import { ROUTES } from '../../../../constants/routes';
import { CommonListHead } from '../../../../components/CommonListHead/CommonListHead';
import { TABLE_HEAD_LIST } from './constants';
import { useSorting } from '../../../../hooks/useSorting';
import { useSnackbar } from 'notistack';

const LIMIT = 10;

export const UsersList: React.FC = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [page, setPage] = useState<number>(1);

  const { sortBy, orderBy, onClickSorting } = useSorting();

  const { data = {}, isLoading } = useFetchUsersQuery(
    { page, sortBy, orderBy },
    { refetchOnMountOrArgChange: true }
  );

  const [deleteUser, { error }] = useDeleteUserMutation();

  useEffect(() => {
    const message = (error as { data: { message: string } })?.data?.message;
    if (message) {
      enqueueSnackbar(message, { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  const { meta = {} } = data;

  const onEditUser = (id: number) => {
    return navigate(`${ROUTES.ADMIN_CONSOLE.USERS.ROOT}/${id}${ROUTES.ADMIN_CONSOLE.USERS.EDIT}`);
  };

  const onDeleteUser = (id: number) => {
    deleteUser({ id });
    setIsOpen(false);
  };

  const handleOpen = (id: number) => {
    setItemId(id);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handlePageChange = useCallback(
    (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
      setPage(newPage + 1);
    },
    []
  );

  return (
    <StateWrapper isLoading={isLoading}>
      <div style={{ width: '100%' }}>
        <TableContainer sx={{ bgcolor: 'background.paper' }} component={Paper}>
          <Table stickyHeader aria-label="simple table" sx={{ minWidth: '850px' }}>
            <CommonListHead
              headListData={TABLE_HEAD_LIST}
              sortBy={sortBy}
              orderBy={orderBy}
              align="center"
              onClick={onClickSorting}
            />
            <TableBody>
              {data.items ? (
                data.items.map((item: User) => (
                  <UserItem
                    key={item.id}
                    item={item}
                    onEdit={onEditUser}
                    onDelete={onDeleteUser}
                    isOpen={isOpen}
                    itemId={itemId}
                    onOpen={handleOpen}
                    onClose={handleClose}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                    <CustomNoRowsOverlay text="Users" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        {meta && (
          <TablePagination
            labelRowsPerPage={matches ? 'Rows per page:' : ''}
            sx={{ bgcolor: 'background.paper' }}
            rowsPerPageOptions={[]}
            component="div"
            count={meta.totalItems}
            rowsPerPage={LIMIT}
            page={meta.currentPage - 1}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </StateWrapper>
  );
};
