import styled from 'styled-components/macro';
import { Theme } from '@mui/material';

export const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '120px',
  height: '55%'
}));

type ItemProps = {
  theme: Theme;
  color?: string;
};

export const Item = styled('div')(({ theme, color }: ItemProps) => ({
  width: '18px',
  height: '18px',
  borderRadius: '50%',
  backgroundColor: color
}));
