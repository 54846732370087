import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderBy, OrderByLabel, SortingOrder } from '../../constants/dashboard';

type SortingProps = {
  sortBy: SortingOrder;
  orderBy: string;
  orderByLabel: string;
};

const initialState: SortingProps = {
  sortBy: SortingOrder.DESC,
  orderBy: OrderBy.LAST_UPDATED,
  orderByLabel: OrderByLabel.LAST_UPDATED
};

export const sortingSlice = createSlice({
  name: 'sorting',
  initialState,
  reducers: {
    setSortBy: (state, action: PayloadAction<any>) => {
      state.sortBy = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<any>) => {
      state.orderBy = action.payload;
    },
    setOrderByLabel: (state, action: PayloadAction<any>) => {
      state.orderByLabel = action.payload;
    }
  }
});
export const { setSortBy, setOrderBy, setOrderByLabel } = sortingSlice.actions;
export default sortingSlice.reducer;
