import React from 'react';
import { RootStyle, Text, Title, Urgency } from './ImprovementItem.styled';
import { Box } from '@mui/material';
import { UrgencyRating } from './UrgencyRating';

type ImprovementItemProps = {
  title: string;
  text: string;
  score: number;
};

export const ImprovementItem: React.FC<ImprovementItemProps> = ({
  title,
  text,
  score
}): JSX.Element => {
  return (
    <RootStyle>
      <Box maxWidth={310}>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Box>
      <Box>
        <Urgency>Urgency</Urgency>
        <UrgencyRating rating={score} />
      </Box>
    </RootStyle>
  );
};
