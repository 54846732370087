import React from 'react';
import { RootStyle, Title } from './TargetImprovements.styled';
import { ImprovementItem } from './ImprovementItem';
import { DashboardRecommendation } from '../../../types/dashboard';

type TargetImprovementsProps = {
  data: DashboardRecommendation[];
};

export const TargetImprovements: React.FC<TargetImprovementsProps> = ({ data }): JSX.Element => {
  return (
    <RootStyle>
      <Title>Target Improvements</Title>
      {data.map(({ id, title, text, score }) => (
        <ImprovementItem key={`improvement-item-${id}`} title={title} text={text} score={score} />
      ))}
    </RootStyle>
  );
};
