import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

export const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: theme.breakpoints.down('sm') ? 'row' : 'column',
  width: '100%',
  height: 'auto',
  '&:not(:last-of-type)': {
    paddingBottom: '11px',
    marginBottom: '14px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.13)'
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: '11px',
  fontSize: '14px',
  fontFamily: 'Helvetica',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '16px',
  color: 'rgba(0, 0, 0, 0.8)'
}));

export const Text = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  width: '90%',
  fontSize: '12px',
  fontFamily: 'Helvetica',
  fontStyle: 'normal',
  color: 'rgba(0, 0, 0, 0.7)',
  fontWeight: 400,
  lineHeight: '14px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: '10px'
  }
}));

export const Urgency = styled(Typography)(({ theme }) => ({
  marginBottom: '10px',
  fontSize: '12px',
  fontFamily: 'Helvetica',
  fontStyle: 'normal',
  lineHeight: '14px',
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.5)'
}));
