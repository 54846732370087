import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { spacing } from '@mui/system';
import Grid from '@mui/material/Grid';
import {
  Container,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Box,
  Link as MuiLink,
  Button,
  useTheme
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as Icon from '@mui/icons-material';
import { DashboardsList } from './List/DashboardsList';
import { ROUTES } from '../../../constants/routes';
import { dashboardAPI } from '../../../services';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const RootStyle = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const DashboardsListPage: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [deleteDashboard] = dashboardAPI.useDeleteDashboardMutation();

  return (
    <RootStyle>
      <Container sx={{ marginTop: 10, marginBottom: 10 }}>
        <Box sx={{ mb: 5 }}>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={8}>
              <Typography variant="h3" gutterBottom>
                List of dashboards
              </Typography>
              <Breadcrumbs>
                <MuiLink component={Link} to={ROUTES.ADMIN_CONSOLE.ROOT}>
                  Dashboard admin
                </MuiLink>
                <Typography>List</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              item
              xs={8}
              display="flex"
              flexDirection={matches ? 'column' : 'row'}
              justifyContent="flex-end"
              alignItems={matches ? 'flex-end' : 'center'}
            >
              <MuiLink
                component={Link}
                to={ROUTES.ADMIN_CONSOLE.DASHBOARDS.ADD}
                underline={'none'}
                ml={4}
                mt={4}
                mb={4}
                width={'100%'}
                maxWidth={'140px'}
              >
                <Button type="button" size="small" variant="contained" color="primary">
                  <Icon.AddCircleOutline sx={{ width: '22px', height: '22px' }} />
                  Add new dashboard
                </Button>
              </MuiLink>
            </Grid>
          </Grid>
        </Box>
        <DashboardsList handleDelete={deleteDashboard} />
      </Container>
    </RootStyle>
  );
};
