import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Link, Outlet, useParams } from 'react-router-dom';
import { Box, Breadcrumbs, Link as MuiLink, Paper as MuiPaper, Typography } from '@mui/material';
import { spacing } from '@mui/system';
import { FilterBar } from './Filterbar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ROUTES } from '../../constants/routes';

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  padding: '24px 0 0 20px',
  flexDirection: 'column'
}));

export const DashboardLayout: React.FC = ({ children }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContent>
      <RootStyle>
        <Box sx={{ height: '70px', marginBottom: '14px' }}>
          <Box sx={{ position: 'fixed' }}>
            <Typography
              mb={2}
              sx={{
                color: '#000000',
                fontSize: '24px',
                fontWeight: 700,
                fontFamily: 'Helvetica'
              }}
            >
              Impact Dashboard
            </Typography>
            {id && (
              <Breadcrumbs>
                <MuiLink component={Link} to={ROUTES.ROOT}>
                  Dashboard cards
                </MuiLink>
                <Typography>Versions</Typography>
              </Breadcrumbs>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start'
          }}
        >
          {matches && <FilterBar />}
          {children}
          <Outlet />
        </Box>
      </RootStyle>
    </MainContent>
  );
};
