import React from 'react';
import { Typography, styled, Box } from '@mui/material';
import { CalculationItem } from './CalculationItem';

const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '359px',
  borderRadius: '8px',
  padding: '20px 20px 0 10px',
  marginBottom: '20px',
  backgroundColor: 'white',
  boxShadow: '0px 2px 7px 0px rgba(46, 56, 77, 0.11)'
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: '15px',
  marginLeft: '10px',
  fontSize: '18px',
  fontStyle: 'normal',
  lineHeight: '21px',
  fontFamily: 'Helvetica',
  fontWeight: 700,
  color: '#000000'
}));

type OptimisationCalculationsProps = {
  materialStrategy: string;
  materialStrategyScore: number;
  productConstruct: string;
  productConstructScore: number;
  lifetime: string;
  lifetimeScore: number;
  recyclability: string;
  recyclabilityScore: number;
  logistics: string;
  logisticsScore: number;
};

export const OptimisationCalculations: React.FC<OptimisationCalculationsProps> = ({
  materialStrategy,
  materialStrategyScore,
  productConstruct,
  productConstructScore,
  lifetime,
  lifetimeScore,
  recyclability,
  recyclabilityScore,
  logistics,
  logisticsScore
}): JSX.Element => {
  return (
    <RootStyle>
      <Title>Optimisation Calculations</Title>
      <Box display="flex" flexWrap="wrap" columnGap="40px">
        <CalculationItem
          percent={materialStrategyScore}
          title="Material Strategy"
          text={materialStrategy}
        />
        <CalculationItem
          percent={productConstructScore}
          title="Product Construct"
          text={productConstruct}
        />
        <CalculationItem percent={lifetimeScore} title="Lifetime" text={lifetime} />
        <CalculationItem percent={recyclabilityScore} title="Recyclability" text={recyclability} />
        <CalculationItem percent={logisticsScore} title="Logistics" text={logistics} />
      </Box>
    </RootStyle>
  );
};
