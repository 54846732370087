import styled from 'styled-components/macro';
import { Box, Chip, Typography } from '@mui/material';

export const Image = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  maxWidth: '100%',
  maxHeight: '100%'
}));

export const Code = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  paddingBottom: '7px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '18px',
  color: 'rgba(0, 0, 0, 0.6)'
}));

export const Brand = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  paddingBottom: '7px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '21px',
  color: 'rgba(0, 0, 0, 0.8)'
}));

export const DateText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '14px',
  color: 'rgba(0, 0, 0, 0.5)'
}));

export const StageChip = styled(Chip)(({ theme }) => ({
  background: 'rgba(199, 202, 243, 0.18)',
  paddingLeft: '1px',
  fontSize: '.75rem',
  cursor: 'pointer'
}));

export const TypeChip = styled(Box)(({ theme }) => ({
  color: '#FEFEFE',
  fontStyle: 'normal',
  padding: '5px 10px',
  fontWeight: 700,
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'flex-end',
  textTransform: 'uppercase',
  fontSize: '11px',
  lineHeight: '13px'
}));
