import { ChartData } from 'chart.js';
import { UPPER_PERCENT_COLOR_LIMIT, CHART_COLORS } from '../constants/chart';

export const getColorByValue = (value: number): string => {
  if (value <= UPPER_PERCENT_COLOR_LIMIT.RED) return CHART_COLORS.RED;
  if (value <= UPPER_PERCENT_COLOR_LIMIT.ORANGE) return CHART_COLORS.ORANGE;
  if (value <= UPPER_PERCENT_COLOR_LIMIT.YELLOW) return CHART_COLORS.YELLOW;
  if (value <= UPPER_PERCENT_COLOR_LIMIT.GREEN) return CHART_COLORS.GREEN;
  return CHART_COLORS.GREEN;
};

export const generateChatData = (value: number): ChartData<'doughnut'> => {
  const color = getColorByValue(value);
  return {
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: [color, '#F2F2F2'],
        hoverOffset: 4
      }
    ]
  };
};
