import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

export const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '359px',
  marginBottom: '20px',
  borderRadius: '10px 10px 8px 8px',
  backgroundColor: '#2E384D'
}));

export const Image = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  maxWidth: '100%',
  maxHeight: '100%'
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginTop: '12px',
  paddingBottom: '13px',
  fontSize: '20px',
  fontFamily: 'Helvetica',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '23px',
  color: '#FFFFFF'
}));

export const Label = styled(Typography)(({ theme }) => ({
  marginBottom: '6px',
  fontSize: '12px',
  fontFamily: 'Helvetica',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '14px',
  color: '#D5D7DB'
}));

export const TextInfo = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Helvetica',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
  color: '#D5D7DB'
}));
