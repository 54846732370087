import { SvgIcon, SvgIconProps } from '@mui/material';

export const SearchIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 19 20" {...props}>
    <path
      d="M18.5658 17.9113L14.6683 13.8444C15.7647 12.3891 16.4245 10.5582 16.4245 8.56842C16.4245 3.84385 12.7406 0 8.21276 0C3.68391 0 0 3.84385 0 8.56842C0 13.2919 3.68391 17.1358 8.21276 17.1358C9.98685 17.1358 11.6273 16.5381 12.9721 15.5364L16.9064 19.6422C17.1358 19.8811 17.4357 20 17.7366 20C18.0365 20 18.3364 19.8811 18.5658 19.6422C19.0235 19.1645 19.0235 18.3901 18.5658 17.9113ZM2.34545 8.56842C2.34545 5.19388 4.97817 2.44857 8.21276 2.44857C11.4474 2.44857 14.078 5.19388 14.078 8.56842C14.078 11.943 11.4474 14.6872 8.21276 14.6872C4.97817 14.6872 2.34545 11.943 2.34545 8.56842Z"
      fill="#8D9AA9"
    />
  </SvgIcon>
);
