import React, { useCallback, useRef, useState } from 'react';
import { Box, Button, Drawer } from '@mui/material';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {
  RootStyle,
  ButtonsCollection,
  Row,
  RowText,
  Search,
  SearchField,
  SearchIconWrapper,
  ToolBar,
  SortingButton
} from './ListToolbar.styled';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FilterBar } from '../../../layouts/Dashboard/Filterbar';
import { SearchIcon } from '../../../assets/icons/SearchIcon';
import { sortingSlice } from '../../../redux/slices/SortingSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHelper';
import { OrderBy, OrderByLabel, SortingOrder } from '../../../constants/dashboard';

type ToolbarProps = {
  searchValue: string;
  onSearch: (newValue: string) => void;
};

type MenuCell = {
  id: string;
  label: string;
  sort: SortingOrder;
};

const MENU_CELLS: MenuCell[] = [
  {
    id: OrderBy.LAST_UPDATED,
    label: OrderByLabel.LAST_UPDATED,
    sort: SortingOrder.DESC
  },
  {
    id: OrderBy.LAST_UPDATED_BY_ME,
    label: OrderByLabel.LAST_UPDATED_BY_ME,
    sort: SortingOrder.DESC
  },
  {
    id: OrderBy.HIGHEST_SCORE,
    label: OrderByLabel.HIGHEST_SCORE,
    sort: SortingOrder.DESC
  },
  {
    id: OrderBy.LOWEST_SCORE,
    label: OrderByLabel.LOWEST_SCORE,
    sort: SortingOrder.ASC
  }
];

export const ListToolbar: React.FC<ToolbarProps> = ({ searchValue, onSearch }): JSX.Element => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const { orderByLabel } = useAppSelector((state) => state.sortingSlicer);
  const { setSortBy, setOrderBy, setOrderByLabel } = sortingSlice.actions;

  const [isShowedMenu, setIsShowedMenu] = useState<boolean>(false);
  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(false);

  useClickOutside(ref, () => setIsShowedMenu(false));

  const handleClick = useCallback(() => {
    setIsShowedMenu((prevState) => !prevState);
  }, []);

  const onChoose = useCallback(
    (item: MenuCell) => {
      dispatch(setOrderBy(item.id));
      dispatch(setOrderByLabel(item.label));
      dispatch(setSortBy(item.sort));
      setIsShowedMenu(false);
    },
    [dispatch, setOrderBy, setOrderByLabel, setSortBy]
  );

  return (
    <RootStyle>
      <Search>
        <SearchIconWrapper>
          <SearchIcon sx={{ width: '20px', height: '20px' }} />
        </SearchIconWrapper>
        <SearchField
          placeholder="Search"
          type="search"
          onChange={(e): void => onSearch(e.target.value)}
          value={searchValue}
        />
      </Search>
      <ButtonsCollection>
        {matches && (
          <>
            <Button
              sx={{ minWidth: 'auto', width: '197px', marginRight: '20px' }}
              variant="contained"
              onClick={() => setIsOpenSidebar(true)}
            >
              Filters
            </Button>
            <Drawer anchor="left" open={isOpenSidebar} onClose={() => setIsOpenSidebar(false)}>
              <FilterBar />
            </Drawer>
          </>
        )}
        <Box ref={ref}>
          <SortingButton
            id="menu-button"
            variant="contained"
            disableElevation
            onClick={handleClick}
            startIcon={
              <SyncAltOutlinedIcon
                sx={{ color: '#8D9AA9', transform: 'rotate(90deg)', width: '20px', height: '20px' }}
              />
            }
            endIcon={
              <ArrowForwardIosOutlinedIcon
                sx={{
                  position: 'absolute',
                  width: '15px',
                  height: '20px',
                  right: '12px',
                  top: '11px',
                  transform: isShowedMenu ? 'rotate(270deg)' : 'rotate(90deg)'
                }}
              />
            }
          >
            <span style={{ whiteSpace: 'nowrap' }}>{orderByLabel}</span>
          </SortingButton>
          {isShowedMenu && (
            <ToolBar>
              {MENU_CELLS.map((item, index) => (
                <Row key={`dropdown-menu-${index}`} onClick={() => onChoose(item)}>
                  <RowText sx={{ fontSize: 14, ml: '8px' }}>{item.label}</RowText>
                </Row>
              ))}
            </ToolBar>
          )}
        </Box>
      </ButtonsCollection>
    </RootStyle>
  );
};
