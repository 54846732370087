import { SvgIcon, SvgIconProps } from '@mui/material';

export const LogoutIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M8.29167 20.75V1.25M18.5833 20.75H3.41667C2.84203 20.75 2.29093 20.5217 1.8846 20.1154C1.47827 19.7091 1.25 19.158 1.25 18.5833V3.41667C1.25 2.84203 1.47827 2.29093 1.8846 1.8846C2.29093 1.47827 2.84203 1.25 3.41667 1.25H18.5833C19.158 1.25 19.7091 1.47827 20.1154 1.8846C20.5217 2.29093 20.75 2.84203 20.75 3.41667V18.5833C20.75 19.158 20.5217 19.7091 20.1154 20.1154C19.7091 20.5217 19.158 20.75 18.5833 20.75Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.167 8.83337L15.0628 11L13.167 13.1667"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
