export const CHART_COLORS = {
  GREEN: '#6CD151',
  YELLOW: '#FED230',
  ORANGE: '#FDA228',
  RED: '#FC341D'
};

export const UPPER_PERCENT_COLOR_LIMIT = {
  GREEN: 100,
  YELLOW: 75,
  ORANGE: 50,
  RED: 25
};
