import React from 'react';
import { Box } from '@mui/material';
import Chart, { Chart as ChartProps } from 'react-chartjs-2';
import { RootStyle, Text, Title } from './CalculationItem.styled';
import { generateChatData } from '../../../utils/chart';

type CalculationItemProps = {
  percent: number;
  title: string;
  text: string;
};

export const CalculationItem: React.FC<CalculationItemProps> = ({
  percent,
  title,
  text
}): JSX.Element => {
  const data = generateChatData(percent);
  return (
    <RootStyle>
      <Box position="relative" width={120} height={120} mr="10px">
        <Chart
          type="doughnut"
          data={data}
          plugins={[
            {
              id: 'doughnut',
              beforeDraw(chart: ChartProps) {
                const { width, height, ctx } = chart;

                ctx.restore();
                ctx.font = `bold 17px Helvetica`;
                ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
                ctx.textBaseline = 'top';

                const text: string = `${percent}%`;
                const textX = Math.round((width - ctx.measureText(text).width) / 2);
                const textY = height / 2.2;

                ctx.fillText(text, textX, textY);
                ctx.save();
              }
            }
          ]}
          options={{
            plugins: {
              tooltip: {
                enabled: false
              }
            },
            layout: {
              padding: {
                bottom: 5
              }
            },
            cutout: 33
          }}
        />
      </Box>
      <Box>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Box>
    </RootStyle>
  );
};
