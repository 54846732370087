import { DashboardItem } from '../types/dashboard';

const DEFAULT_OPTION = {
  id: -1,
  name: ''
};

export const IMPACT_DASHBOARD_DEFAULT_DATA: DashboardItem = {
  id: -1,
  company: DEFAULT_OPTION,
  brandGroup: '',
  brand: '',
  logo: '',
  brandPicture: '',
  launchDate: '',
  designOrigin: '',
  sustainabilityOptimisation: '',
  componentCount: 0,
  materialCount: 0,
  estimatedProductQuantity: 0,
  packedDimensions: '',
  materialStrategy: '',
  productConstruct: '',
  recylability: '',
  lifetime: '',
  logistics: '',
  notesToClient: '',
  sustainabilityScore: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  materialGroups: [],
  materialLists: [],
  productionLocations: [],
  distributionTerritories: [],
  dashboardRecommendations: [],
  primaryPointer: null,
  version: '',
  SKU: '',
  stage: DEFAULT_OPTION,
  type: DEFAULT_OPTION,
  materialStrategyScore: 0,
  productConstructScore: 0,
  recylabilityScore: 0,
  lifetimeScore: 0,
  logisticsScore: 0,
  notes: '',
  tbc: false
};

type StageColorProps = {
  [key: string]: string;
};

export const StageColor: StageColorProps = {
  1: '#7879F1',
  2: '#F2994A',
  3: '#27AE60',
  4: '#BDBDBD',
  5: '#BDBDBD'
};

type TypeColorProps = {
  [key: number]: string;
};

export const TypeColor: TypeColorProps = {
  3: '#68DBF2',
  1: '#66CB9F',
  2: '#F7936F'
};

export enum OrderBy {
  HIGHEST_SCORE = 'sustainabilityScore',
  LOWEST_SCORE = 'sustainabilityScore',
  LAST_UPDATED = 'updatedAt',
  LAST_UPDATED_BY_ME = 'lastUpdatedByMe'
}

export enum OrderByLabel {
  HIGHEST_SCORE = 'Highest score',
  LOWEST_SCORE = 'Lowest score',
  LAST_UPDATED_BY_ME = 'Last updated by me',
  LAST_UPDATED = 'Last updated'
}

export enum SortingOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export type HeadCell = {
  id: string;
  label?: string;
};
