import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Button, TextField as MuiTextField } from '@mui/material';
import { spacing } from '@mui/system';

import useAuth from '../../hooks/useAuth';
import { PasswordInput } from '../PasswordInput';
import { useFormik } from 'formik';
import { signUpRules } from './rules';

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  submit: false
};

type SignUpValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  submit: boolean;
};

const SignUp: React.FC = () => {
  const { signUp } = useAuth();

  const { errors, handleBlur, handleChange, handleSubmit, touched, values } =
    useFormik<SignUpValues>({
      initialValues: DEFAULT_VALUES,
      validationSchema: signUpRules,
      onSubmit: async (values) => {
        signUp(
          values.firstName,
          values.lastName,
          values.email,
          values.password,
          values.confirmPassword
        );
      }
    });

  return (
    <form noValidate onSubmit={handleSubmit}>
      <TextField
        type="firstName"
        name="firstName"
        label="First name"
        value={values.firstName}
        error={Boolean(touched.firstName && errors.firstName)}
        fullWidth
        helperText={touched.firstName && errors.firstName}
        onBlur={handleBlur}
        onChange={handleChange}
        my={3}
      />
      <TextField
        type="lastName"
        name="lastName"
        label="Last name"
        value={values.lastName}
        error={Boolean(touched.lastName && errors.lastName)}
        fullWidth
        helperText={touched.lastName && errors.lastName}
        onBlur={handleBlur}
        onChange={handleChange}
        my={3}
      />
      <TextField
        type="email"
        name="email"
        label="Email address"
        value={values.email}
        error={Boolean(touched.email && errors.email)}
        fullWidth
        helperText={touched.email && errors.email}
        onBlur={handleBlur}
        onChange={handleChange}
        my={3}
      />
      <PasswordInput
        value={values.password}
        name="password"
        label="Password"
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(touched.password && errors.password)}
        helperText={touched.password && errors.password}
        my={3}
      />
      <PasswordInput
        value={values.confirmPassword}
        name="confirmPassword"
        label="Confirm password"
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
        helperText={touched.confirmPassword && errors.confirmPassword}
        my={3}
      />
      <Button type="submit" fullWidth variant="contained" color="primary">
        Create Account
      </Button>
      <Button component={Link} to="/auth/sign-in" fullWidth color="primary">
        Log in
      </Button>
    </form>
  );
};

export default SignUp;
