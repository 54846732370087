import React from 'react';
import { Drawer, IconButton, Link as MuiLink, Stack } from '@mui/material';
import { customOrange } from '../../theme/variants';
import styled from 'styled-components/macro';
import useAuth from '../../hooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../constants';
import { LogoIcon } from '../../assets/icons/icon_logo';
import { SettingsIcon } from '../../assets/icons/SettingsIcon';
import { AnalyticsIcon } from '../../assets/icons/AnalyticsIcon';
import { MenuIcon } from '../../assets/icons/MenuIcon';
import { LogoutIcon } from '../../assets/icons/LogoutIcon';
import { ROUTES } from '../../constants/routes';

const IconButtonItem = styled(IconButton)(({ theme }) => ({
  width: '100%',
  height: '48px',
  border: '3px solid transparent',
  color: '#000000',
  '&:hover': {
    borderRadius: 0,
    bgcolor: 'transparent',
    borderLeft: `3px solid ${customOrange[700]}`,
    color: customOrange[700]
  }
}));

export const LeftSidebar: React.FC = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleSignOut = async () => {
    await signOut();
    navigate('/auth/sign-in');
  };

  return (
    <Drawer
      open={true}
      variant="persistent"
      PaperProps={{
        sx: {
          width: 72,
          height: '100vh',
          position: 'fixed',
          overflowX: 'hidden',
          borderRight: 0,
          alignItems: 'center'
        }
      }}
    >
      <div style={{ padding: '24px 16px 10px 16px' }}>
        <MuiLink
          component={Link}
          to={PATH_DASHBOARD.ROOT}
          sx={{ '&:hover': { textDecoration: 'none' } }}
        >
          <LogoIcon />
        </MuiLink>
      </div>
      <IconButtonItem>
        <MenuIcon sx={{ width: '24px', height: '24px' }} />
      </IconButtonItem>
      <IconButtonItem>
        <AnalyticsIcon sx={{ width: '37px', height: '37px' }} />
      </IconButtonItem>

      <Stack direction="column" alignItems="center" bottom={10} width="100%" position="absolute">
        <IconButtonItem onClick={() => navigate(ROUTES.ADMIN_CONSOLE.ROOT)}>
          <SettingsIcon sx={{ width: '24px', height: '24px' }} />
        </IconButtonItem>
        <IconButtonItem onClick={handleSignOut}>
          <LogoutIcon sx={{ width: '24px', height: '24px' }} />
        </IconButtonItem>
      </Stack>
    </Drawer>
  );
};
