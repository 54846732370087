import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DashboardItem, DashboardListItem } from '../types/dashboard';
import { API_ROUTES } from '../constants/api.routes';
import { CopyVersionParams, VersionItem } from '../types/version';
import { SortingOrder } from '../constants/dashboard';

type FetchVersionsQueryParams = {
  primaryPointer: string;
};

type DashboardListData = {
  dashboards: DashboardListItem[];
  total: number;
};

type FetchAllDashboardsQueryParams = {
  limit?: number;
  page?: number;
  sortBy?: string;
  orderBy?: string;
  searchTerm?: string;
  brands?: string;
  stages?: string;
  types?: string;
};

export const dashboardAPI = createApi({
  reducerPath: 'dashboardAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['Dashboards', 'Versions'],
  endpoints: (build) => ({
    fetchAllDashboards: build.query<DashboardListData, FetchAllDashboardsQueryParams>({
      query: ({
        limit,
        page,
        brands,
        stages,
        types,
        sortBy = SortingOrder.DESC,
        orderBy,
        searchTerm
      }) => ({
        url: API_ROUTES.DASHBOARD.ROOT,
        params: {
          limit,
          page,
          orderBy,
          sortBy,
          searchTerm,
          brands,
          stages,
          types
        }
      }),
      keepUnusedDataFor: 0.01,
      providesTags: ['Dashboards']
    }),
    fetchDashboardById: build.query<DashboardItem, { id?: string }>({
      query: ({ id }) => ({
        url: `${API_ROUTES.DASHBOARD.ROOT}/${id}`
      }),
      providesTags: ['Dashboards']
    }),
    createDashboard: build.mutation<DashboardItem, FormData>({
      query: (dashboard: FormData) => ({
        url: API_ROUTES.DASHBOARD.ROOT,
        method: 'POST',
        body: dashboard
      }),
      invalidatesTags: ['Dashboards', 'Versions']
    }),
    updateDashboard: build.mutation<DashboardItem, FormData>({
      query: (dashboard: FormData) => ({
        url: API_ROUTES.DASHBOARD.ROOT,
        method: 'PUT',
        body: dashboard
      }),
      invalidatesTags: ['Dashboards', 'Versions']
    }),
    deleteDashboard: build.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `${API_ROUTES.DASHBOARD.DELETE}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Dashboards', 'Versions']
    }),
    createRecommendation: build.mutation<any, any>({
      query: (recommendation: any) => ({
        url: API_ROUTES.DASHBOARD.RECOMMENDATION,
        method: 'POST',
        body: recommendation
      }),
      invalidatesTags: ['Dashboards', 'Versions']
    }),
    updateRecommendation: build.mutation<any, any>({
      query: (body) => ({
        url: API_ROUTES.DASHBOARD.RECOMMENDATION,
        method: 'PUT',
        body: body
      }),
      invalidatesTags: ['Dashboards', 'Versions']
    }),
    deleteRecommendation: build.mutation<any, number>({
      query: (id: number) => ({
        url: `${API_ROUTES.DASHBOARD.RECOMMENDATION}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Dashboards', 'Versions']
    }),
    fetchVersions: build.query<VersionItem[], FetchVersionsQueryParams>({
      query: ({ primaryPointer }) => ({
        url: `${API_ROUTES.DASHBOARD.VERSIONS}/${primaryPointer}`
      }),
      providesTags: ['Versions', 'Dashboards']
    }),
    copyVersion: build.mutation<VersionItem, CopyVersionParams>({
      query: (version: CopyVersionParams) => ({
        url: API_ROUTES.DASHBOARD.VERSION,
        method: 'POST',
        body: version
      }),
      invalidatesTags: ['Versions', 'Dashboards']
    }),
    deleteVersion: build.mutation<
      { nextPrimaryDashboardId: number | null },
      string | number | undefined
    >({
      query: (id) => ({
        url: `${API_ROUTES.DASHBOARD.ROOT}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Versions', 'Dashboards']
    }),
    updatePrimaryPointer: build.mutation<VersionItem, string | number | undefined>({
      query: (id) => ({
        url: `${API_ROUTES.DASHBOARD.VERSION}/${id}`,
        method: 'PATCH'
      }),
      invalidatesTags: ['Versions', 'Dashboards']
    }),
    updateVersion: build.mutation<DashboardItem, FormData>({
      query: (dashboard: FormData) => ({
        url: API_ROUTES.DASHBOARD.ROOT,
        method: 'PUT',
        body: dashboard
      }),
      invalidatesTags: ['Versions', 'Dashboards']
    })
  })
});
