import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { RootStyle, Item } from './UrgencyRating.styled';
import { makeColorsArray } from '../../../utils/dashboard';

type UrgencyRatingProps = {
  rating: number;
};

export const UrgencyRating: React.FC<UrgencyRatingProps> = ({ rating }): JSX.Element => {
  const colors = makeColorsArray(rating);
  return (
    <RootStyle>
      {colors.map((color) => (
        <Item key={uuidv4()} color={color} />
      ))}
    </RootStyle>
  );
};
