import * as React from 'react';
import styled from 'styled-components/macro';
import { Link, useParams } from 'react-router-dom';
import { spacing } from '@mui/system';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Box,
  Link as MuiLink,
  Container
} from '@mui/material';
import { dashboardAPI } from '../../../../services';
import { ROUTES } from '../../../../constants/routes';
import { DashboardForm } from '../../../../components/DashboardForm/DashboardForm';
import { useCallback } from 'react';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const EditBrandDashboard: React.FC = () => {
  const { id = '' } = useParams();

  const { data, isLoading } = dashboardAPI.useFetchDashboardByIdQuery(
    { id },
    { refetchOnMountOrArgChange: true }
  );

  const [
    updateDashboard,
    { isSuccess: isSuccessUpdate, error: updateError, isLoading: isLoadingUpdate }
  ] = dashboardAPI.useUpdateDashboardMutation();

  const handleRequestUpdate = useCallback(
    (values: FormData) => {
      updateDashboard(values);
    },
    [updateDashboard]
  );

  return (
    <Container maxWidth="lg" sx={{ paddingTop: '30px' }}>
      <Box sx={{ mb: 5 }}>
        <Typography variant="h3" gutterBottom>
          Edit Brand Dashboard
        </Typography>
        <Breadcrumbs>
          <MuiLink component={Link} to={ROUTES.ADMIN_CONSOLE.ROOT}>
            Dashboard admin
          </MuiLink>
          <MuiLink component={Link} to={ROUTES.ADMIN_CONSOLE.DASHBOARDS.LIST}>
            List
          </MuiLink>
          <Typography>Edit dashboard</Typography>
        </Breadcrumbs>
        <DashboardForm
          dashboardId={id}
          data={data}
          isLoading={isLoading}
          handleRequest={handleRequestUpdate}
          isSuccessRequest={isSuccessUpdate}
          error={updateError}
          isLoadingRequest={isLoadingUpdate}
          route={ROUTES.ADMIN_CONSOLE.DASHBOARDS.LIST}
        />
      </Box>
    </Container>
  );
};
