import styled from 'styled-components/macro';
import { Typography, Box } from '@mui/material';

export const RootStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '20px 20px 8px 20px',
  borderRadius: '8px',
  backgroundColor: '#EFF1F5'
}));

export const Title = styled(Typography)(({ theme }) => ({
  margin: '0 0 16px 0',
  padding: 0,
  fontSize: '18px',
  fontFamily: 'Helvetica',
  fontWeight: 700,
  color: '#000000',
  wordBreak: 'break-all',
  lineHeight: '21px'
}));

export const Label = styled(Typography)(({ theme }) => ({
  marginBottom: '6px',
  fontSize: '12px',
  fontStyle: 'normal',
  fontFamily: 'Helvetica',
  lineHeight: '14px',
  fontWeight: '700',
  color: 'rgba(0, 0, 0, 0.8)'
}));

export const TextInfo = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Helvetica',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '16px',
  color: 'rgba(0, 0, 0, 0.8)',
  wordBreak: 'break-all'
}));
