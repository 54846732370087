import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { JWTContextType, ActionMap, AuthState, AuthUser } from '../types/auth';
import axios from '../utils/axios';
import { setSession } from '../utils/jwt';
import { API_ROUTES } from '../constants/api.routes';

const INITIALIZE = 'INITIALIZE';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const SIGN_UP = 'SIGN_UP';
const VERIFIED = 'VERIFIED';
const CONFIRM_VERIFIED = 'CONFIRM_VERIFIED';
const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const RESET_PASSWORD = 'RESET_PASSWORD';

const APP_TYPE = 'USER';

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_IN]: {
    email: string;
  };
  [SIGN_OUT]: undefined;
  [SIGN_UP]: {
    accessToken: string;
  };
  [FORGOT_PASSWORD]: {
    status: boolean;
  };
  [RESET_PASSWORD]: {
    status: boolean;
  };
  [VERIFIED]: {
    status: boolean;
  };
  [CONFIRM_VERIFIED]: {
    accessToken: string;
  };
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  email: '',
  user: null
};

const JWTReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case SIGN_IN:
      return {
        ...state,
        email: action.payload.email
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    case SIGN_UP:
      return {
        ...state,
        accessToken: action.payload.accessToken
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        status: action.payload.status
      };
    case RESET_PASSWORD:
      return {
        ...state,
        status: action.payload.status
      };
    case VERIFIED:
      return {
        ...state,
        status: action.payload.status
      };
    case CONFIRM_VERIFIED:
      return {
        ...state,
        isAuthenticated: true,
        accessToken: action.payload.accessToken
      };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const errorHandler = (error: any) => {
    let message: string = error.message || 'Something went wrong!';

    if (error?.statusCode === 401) {
      message = 'You are not authorized!';
    }

    if (error?.statusCode === 400) {
      message = error.message;
    }

    enqueueSnackbar(message, {
      variant: 'error'
    });
  };

  const messageHandler = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'success'
    });
  };

  const initialize = async (accessToken: string | null) => {
    try {
      if (accessToken) {
        setSession(accessToken);

        const response = await axios.get(API_ROUTES.AUTH.PROFILE);
        const { data } = response;

        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: true,
            user: data
          }
        });
      } else {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err: any) {
      console.error(err);
      errorHandler(err);

      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  useEffect(() => {
    const accessToken = window.localStorage.getItem('accessToken');
    initialize(accessToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      const response = await axios.post(API_ROUTES.AUTH.LOGIN, {
        email,
        password,
        role: APP_TYPE
      });
      const { statusCode, message } = response.data;

      if (statusCode === 200) {
        messageHandler(message);
        dispatch({
          type: SIGN_IN,
          payload: {
            email
          }
        });
        navigate('/auth/send-2fa/code');
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    passwordConfirm: string
  ) => {
    try {
      const response = await axios.post(API_ROUTES.AUTH.SIGN_UP, {
        firstName,
        lastName,
        email,
        password,
        passwordConfirmation: passwordConfirm,
        role: APP_TYPE
      });
      const { statusCode, message } = response.data;

      if (statusCode === 200) {
        messageHandler(message);
      }
    } catch (err) {
      console.log('signUp', err);
      errorHandler(err);
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      const response = await axios.post(API_ROUTES.AUTH.FORGOT_PASSWORD, {
        email
      });
      const { statusCode, message } = response.data;

      if (statusCode) {
        messageHandler(message);
      }
    } catch (err) {
      console.log('forgotPassword', err);
      errorHandler(err);
    }
  };

  const resetPassword = async (password: string, token: string) => {
    try {
      const response = await axios.post(API_ROUTES.AUTH.RESET_PASSWORD, {
        password,
        token
      });
      const { statusCode, message } = response.data;

      if (statusCode) {
        messageHandler(message);
        navigate('/auth/sign-in');
      }
    } catch (err) {
      console.log('Reset password: ', err);
      errorHandler(err);
    }
  };

  const confirmUpdateEmail = async (email: string, registrationCode: string) => {
    try {
      const response = await axios.post(API_ROUTES.AUTH.CONFIRM_UPDATE_EMAIL, {
        email,
        registrationCode,
        accept: true
      });
      const { message } = response.data;

      messageHandler(message);
      navigate('/');
    } catch (err) {
      console.log('resetPassword', err);
      errorHandler(err);
    }
  };

  const inviteRegister = async (
    firstName: string,
    lastName: string,
    password: string,
    passwordConfirm: string,
    registrationCode: string | null
  ) => {
    try {
      const response = await axios.post(API_ROUTES.AUTH.INVITE_REGISTER, {
        firstName,
        lastName,
        password,
        passwordConfirmation: passwordConfirm,
        registrationCode
      });
      const { accessToken } = response.data;

      if (accessToken) {
        initialize(accessToken);
      }
    } catch (err) {
      console.error('Error with Invite Register: ', err);
      errorHandler(err);
    }
  };

  const checkRegistrationCode = async (registrationCode: string) => {
    try {
      const response = await axios.get(`${API_ROUTES.AUTH.CHECK_CODE}/${registrationCode}`);
      const { isExist } = response.data;

      if (!isExist) {
        navigate('/');
      }
    } catch (err: any) {
      console.error('Verified - error: ', err);
      if (err?.message) {
        enqueueSnackbar(err?.message, {
          variant: 'error'
        });
      } else {
        errorHandler(err);
      }
    }
  };

  const verified = async (
    email?: string,
    phoneNumber?: string,
    registrationCode?: string,
    handleNext?: () => void
  ) => {
    try {
      const response = await axios.post(API_ROUTES.AUTH.SEND_CODE, {
        email,
        phoneNumber,
        registrationCode
      });

      const { statusCode } = response.data;

      if (statusCode) {
        dispatch({
          type: VERIFIED,
          payload: {
            status: statusCode
          }
        });
        if (typeof handleNext !== 'undefined') {
          handleNext();
        }
      }
    } catch (err: any) {
      console.error('Verified - error: ', err);
      if (err?.message) {
        enqueueSnackbar(err?.message, {
          variant: 'error'
        });
      } else {
        errorHandler(err);
      }
    }
  };

  const confirmVerified = async (registrationCode: string | null, activationCode: string) => {
    try {
      const response = await axios.post(API_ROUTES.AUTH.CONFIRM_CODE, {
        registrationCode,
        activationCode
      });
      const { accessToken } = response.data;

      window.localStorage.setItem('accessToken', accessToken);
      dispatch({
        type: CONFIRM_VERIFIED,
        payload: {
          accessToken
        }
      });
      initialize(accessToken);
    } catch (err) {
      console.error('Confirm-verified - error: ', err);
      enqueueSnackbar('The code was entered incorrectly', {
        variant: 'error'
      });
    }
  };

  const loginConfirmVerified = async (email: string, activationCode: string) => {
    try {
      const response = await axios.post(API_ROUTES.AUTH.LOGIN_CONFIRM, {
        email,
        activationCode
      });
      const { accessToken } = response.data;

      window.localStorage.setItem('accessToken', accessToken);
      dispatch({
        type: CONFIRM_VERIFIED,
        payload: {
          accessToken
        }
      });
      initialize(accessToken);
    } catch (err) {
      console.error('Confirm-verified - error: ', err);
      enqueueSnackbar('The code was entered incorrectly', {
        variant: 'error'
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        signIn,
        signOut,
        signUp,
        forgotPassword,
        resetPassword,
        confirmUpdateEmail,
        inviteRegister,
        checkRegistrationCode,
        verified,
        confirmVerified,
        loginConfirmVerified
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
