import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type FiltersProps = {
  brands: string[];
  stages: number[];
  types: number[];
};

const initialState: FiltersProps = {
  brands: [],
  stages: [],
  types: []
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setBrands: (state, action: PayloadAction<any>) => {
      state.brands = action.payload;
    },
    setStages: (state, action: PayloadAction<any>) => {
      state.stages = action.payload;
    },
    setTypes: (state, action: PayloadAction<any>) => {
      state.types = action.payload;
    }
  }
});
export const { setBrands, setStages, setTypes } = filtersSlice.actions;
export default filtersSlice.reducer;
