import * as Yup from 'yup';

const REGEX_WITHOUT_SPACES = /^[-a-zA-Z\u00C0-\u00FF'-]+(\s+[-a-zA-Z\u00C0-\u00FF'-]+)*$/;
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const signInRules = Yup.object().shape({
  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  password: Yup.string().max(255).required('Password is required')
});

export const signUpRules = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required')
    .matches(REGEX_WITHOUT_SPACES, 'Incorrect first name form'),
  lastName: Yup.string()
    .required('Last name is required')
    .matches(REGEX_WITHOUT_SPACES, 'Incorrect last name form'),
  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  password: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .max(255)
    .required('Required')
    .matches(
      PASSWORD_REGEX,
      'Password must contain at least 8 characters, one uppercase, one lowercase letter, one number and one special case character'
    ),
  confirmPassword: Yup.string()
    .required('Required')
    .when('password', {
      is: (val: any) => val && val.length > 0,
      then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same')
    })
});

export const inviteRegisterRules = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required')
    .matches(REGEX_WITHOUT_SPACES, 'Incorrect first name form'),
  lastName: Yup.string()
    .required('Last name is required')
    .matches(REGEX_WITHOUT_SPACES, 'Incorrect last name form'),
  password: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .max(255)
    .required('Required')
    .matches(
      PASSWORD_REGEX,
      'Password must contain at least 8 characters, one uppercase, one lowercase letter, one number and one special case character'
    ),
  confirmPassword: Yup.string()
    .required('Required')
    .when('password', {
      is: (val: string) => val && val.length > 0,
      then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same')
    })
});

export const forgotPasswordRules = Yup.object().shape({
  email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
});

export const resetPasswordRules = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .max(255)
    .required('Required')
    .matches(
      PASSWORD_REGEX,
      'Password must contain at least 8 characters, one uppercase, one lowercase letter, one number and one special case character'
    )
});
