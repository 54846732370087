import { Box, Button, InputBase, Typography } from '@mui/material';
import styled from 'styled-components/macro';

export const RootStyle = styled(Box)(({ theme }) => ({
  marginBottom: '16px',
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginRight: '20px'
  },
  [theme.breakpoints.between(1080, 1415)]: {
    width: '672px'
  },
  [theme.breakpoints.between(1416, 1752)]: {
    width: '1008px'
  },
  [theme.breakpoints.between(1753, 2089)]: {
    width: '1344px'
  },
  [theme.breakpoints.between(2090, 2426)]: {
    width: '1680px'
  },
  [theme.breakpoints.between(2427, 2763)]: {
    width: '2016px'
  },
  [theme.breakpoints.between(2764, 3100)]: {
    width: '2352px'
  }
}));

export const ButtonsCollection = styled(Box)(({ theme }) => ({
  paddingRight: '20px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    marginTop: '16px',
    justifyContent: 'space-between'
  }
}));

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '6px',
  display: 'flex',
  border: '1px solid #EAECF6',
  backgroundColor: '#FFFFFF',
  marginLeft: 0,
  marginRight: '20px',
  maxWidth: '540px',
  height: '44px',
  minWidth: '200px',
  width: '540px',
  [theme.breakpoints.down('md')]: {
    width: 'auto',
    maxWidth: '100%'
  }
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  color: 'rgba(152, 152, 152, 0.61)',
  padding: '0 14px',
  position: 'absolute',
  width: '48px',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const SearchField = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 2, 1, 0),
    fontSize: '14px',
    fontFamily: 'Helvetica',
    height: '44px',
    paddingLeft: '48px',
    color: 'rgba(0, 0, 0, 0.7)',
    transition: theme.transitions.create('width'),
    width: '100%'
  },
  input: {
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 1)',
      fontFamily: 'Helvetica',
      fontStyle: 'normal',
      opacity: 0.8,
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '16px'
    }
  }
}));

export const ToolBar = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '197px',
  height: 'auto',
  borderRadius: '6px',
  transform: 'translate(0, 3px)',
  zIndex: 10,
  background: 'white',
  boxShadow: ' 0px 2px 22px 0px #2E384D1C'
}));

export const Row = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 0 8px 18px',
  color: 'black',
  cursor: 'pointer',
  '&:hover': {
    background: '#F0F2F7',
    borderRadius: 'inherit'
  }
}));

export const RowText = styled(Typography)(() => ({
  fontSize: 14,
  marginLeft: '8px'
}));

export const SortingButton = styled(Button)(() => ({
  background: '#FFFFFF',
  color: 'rgba(0, 0, 0, 0.8)',
  fontSize: '14px',
  padding: '0 14px',
  justifyContent: 'flex-start',
  border: '1px solid #EAECF6',
  borderRadius: '6px',
  height: '44px',
  width: '197px',
  minWidth: '197px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));
