import { SvgIcon, SvgIconProps } from '@mui/material';

export const AnalyticsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M8 16v-5m4 5V8m4 8v-2m2-10H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"
    />
  </SvgIcon>
);
