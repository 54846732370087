import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

export const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  minHeight: '380px',
  padding: '26px 24px 22px 20px',
  borderRadius: '8px',
  backgroundColor: 'white',
  boxShadow: '0px 2px 7px 0px rgba(46, 56, 77, 0.11)'
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: '16px',
  fontSize: '18px',
  fontFamily: 'Helvetica',
  fontWeight: 700,
  fontStyle: 'normal',
  lineHeight: '21px',
  color: '#000000'
}));
