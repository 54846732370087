type Data = {
  id: number;
  name: string;
};

export function makeStringFromArray<T = Data>(array: T[], predicate: keyof T): string {
  return array.map((item) => item[predicate]).join(', ') || '';
}

const GREY = 'rgba(196, 196, 196, 1)';

const COLORS = {
  YELLOW: '#FED230',
  LIGHT_ORANGE: '#FDA228',
  ORANGE: '#FD7A23',
  DARK_ORANGE: '#FD5A23',
  RED: '#F50000'
};

const COLORS_ARRAY = Object.values(COLORS);
const DEFAULT_COLORS = [GREY, GREY, GREY, GREY, GREY];

export const makeColorsArray = (rating: number): string[] => [
  ...COLORS_ARRAY.slice(0, rating),
  ...DEFAULT_COLORS.slice(0, DEFAULT_COLORS.length - rating)
];
