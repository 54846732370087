import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { dashboardAPI } from '../../../../services';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import CustomNoRowsOverlay from '../../../../components/NoData/NoData';
import { StateWrapper } from '../../../../components/StateWrapper';
import { DashboardItem } from './DashboardItem';
import { ROUTES } from '../../../../constants/routes';
import { CommonListHead } from '../../../../components/CommonListHead/CommonListHead';
import { TABLE_HEAD_LIST } from './constants';
import { useSorting } from '../../../../hooks/useSorting';
import { DashboardListItem } from '../../../../types/dashboard';

interface DashboardListProps {
  handleDelete: MutationTrigger<any>;
}

const DEFAULT_DATA = {
  dashboards: [],
  total: 0
};

const LIMIT = 10;

export const DashboardsList: React.FC<DashboardListProps> = ({ handleDelete }) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [page, setPage] = useState<number>(1);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const { sortBy, orderBy, onClickSorting } = useSorting();

  const { data = DEFAULT_DATA, isLoading } = dashboardAPI.useFetchAllDashboardsQuery(
    { page, sortBy, orderBy: orderBy || 'updatedAt' },
    { refetchOnMountOrArgChange: true }
  );

  const handlePageChange = useCallback(
    (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
      setPage(newPage + 1);
    },
    []
  );

  const onEditDashboard = (id: number) => {
    return navigate(
      `${ROUTES.ADMIN_CONSOLE.DASHBOARDS.ROOT}/${id}${ROUTES.ADMIN_CONSOLE.DASHBOARDS.EDIT}`
    );
  };

  const onDeleteDashboard = (id: number) => {
    handleDelete({ id });
    setIsOpen(false);
  };

  const handleOpen = (id: number) => {
    setItemId(id);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <StateWrapper isLoading={isLoading}>
      <div style={{ width: '100%' }}>
        <TableContainer sx={{ bgcolor: 'background.paper' }} component={Paper}>
          <Table stickyHeader aria-label="simple table" sx={{ minWidth: '850px' }}>
            <CommonListHead
              headListData={TABLE_HEAD_LIST}
              sortBy={sortBy}
              orderBy={orderBy}
              onClick={onClickSorting}
            />
            <TableBody>
              {data.dashboards ? (
                data.dashboards?.map((item: DashboardListItem) => (
                  <DashboardItem
                    key={item.id}
                    item={item}
                    onEdit={onEditDashboard}
                    onDelete={onDeleteDashboard}
                    isOpen={isOpen}
                    itemId={itemId}
                    onOpen={handleOpen}
                    onClose={handleClose}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                    <CustomNoRowsOverlay text="Dashboards" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {data.total && (
          <>
            <Divider />
            <TablePagination
              labelRowsPerPage={matches ? 'Rows per page:' : ''}
              sx={{ bgcolor: 'background.paper' }}
              rowsPerPageOptions={[]}
              component="div"
              count={data.total}
              rowsPerPage={LIMIT}
              page={page - 1}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </div>
    </StateWrapper>
  );
};
