import React from 'react';
import styled from 'styled-components/macro';
import { Container, Grid } from '@mui/material';
import { Header } from './Header';
import { MainDetail } from './MainDetail';
import { ProductDetail } from './ProductDetail';
import { OptimisationCalculations } from './OptimisationCalculations';
import { TargetImprovements } from './TargetImprovements';
import { SustainabilityScore } from './SustainabilityScore';
import { useParams } from 'react-router-dom';
import { QueryParams } from '../../types/common';
import { dashboardAPI } from '../../services';
import { IMPACT_DASHBOARD_DEFAULT_DATA } from '../../constants/dashboard';
import { StateWrapper } from '../../components/StateWrapper';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center'
}));

export const ImpactDashboard: React.FC = () => {
  const { id = '', versionId } = useParams<QueryParams>();

  const { data = IMPACT_DASHBOARD_DEFAULT_DATA, isLoading } =
    dashboardAPI.useFetchDashboardByIdQuery({ id: versionId }, { skip: !versionId });

  return (
    <StateWrapper isLoading={isLoading}>
      <RootStyle>
        <Container
          disableGutters
          maxWidth="xl"
          sx={{ padding: '0 24px 0 20px', marginTop: '20px', marginBottom: '32px' }}
        >
          <Header versionName={data.version} dashboardId={id} company={data.company} />
          <Grid container spacing={5} columns={12} justifyContent="space-between">
            <Grid item xs={12} sm={5} md={3}>
              <MainDetail
                brand={data.brand}
                brandGroup={data.brandGroup}
                launchDate={data.launchDate}
                brandPicture={data.brandPicture}
                SKU={data.SKU}
              />
              <ProductDetail data={data} />
            </Grid>
            <Grid item xs={12} sm={7} md={9}>
              <OptimisationCalculations
                materialStrategy={data.materialStrategy}
                materialStrategyScore={data.materialStrategyScore}
                productConstruct={data.productConstruct}
                productConstructScore={data.productConstructScore}
                lifetime={data.lifetime}
                lifetimeScore={data.lifetimeScore}
                recyclability={data.recylability}
                recyclabilityScore={data.recylabilityScore}
                logistics={data.logistics}
                logisticsScore={data.logisticsScore}
              />
              <Grid container spacing={5} columns={18}>
                <Grid item xs={18} md={10}>
                  <TargetImprovements data={data.dashboardRecommendations} />
                </Grid>
                <Grid item xs={18} md={8}>
                  <SustainabilityScore score={data.sustainabilityScore} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </RootStyle>
    </StateWrapper>
  );
};
