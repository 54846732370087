import React, { useEffect } from 'react';
import { Box, Button, Grid, IconButton, Rating, Stack, TextField, Typography } from '@mui/material';
import { FieldArray, FormikErrors, FormikProvider, useFormik } from 'formik';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { dashboardAPI } from '../../services';
import { RecommendationItem } from '../../types/dashboard';
import { recommendationsRules } from './rules';
import { CountingTextField } from '../CountingTextField';
import { useSnackbar } from 'notistack';

const emptyItems: RecommendationItem = {
  title: '',
  text: '',
  score: 0
};

const RECOMMENDATIONS_LIMIT = 3;

type RecommendationsProps = {
  dashboardId: string;
  recommendations: RecommendationItem[];
};

type RecommendationsValues = {
  recommendations: RecommendationItem[];
};

export const Recommendations: React.FC<RecommendationsProps> = ({
  dashboardId,
  recommendations
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createRecommendation, { isSuccess: isSuccessCreate }] =
    dashboardAPI.useCreateRecommendationMutation();
  const [updateRecommendation, { isSuccess: isSuccessUpdate }] =
    dashboardAPI.useUpdateRecommendationMutation();
  const [deleteRecommendation] = dashboardAPI.useDeleteRecommendationMutation();

  useEffect(() => {
    if (isSuccessCreate || isSuccessUpdate) {
      enqueueSnackbar('Changes saved', {
        variant: 'success'
      });
    }
  }, [enqueueSnackbar, isSuccessCreate, isSuccessUpdate]);

  const formik = useFormik<RecommendationsValues>({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      recommendations: !!recommendations.length ? recommendations : [emptyItems]
    },
    validationSchema: recommendationsRules,
    onSubmit: async (values) => {}
  });

  const { getFieldProps, setFieldValue, values, touched, errors } = formik;

  const createHandler = (item: RecommendationItem) => {
    createRecommendation({ ...item, dashboardId: Number(dashboardId) });
  };
  const updateHandler = (item: RecommendationItem) => {
    updateRecommendation(item);
  };

  const deleteHandler = (
    id: number | undefined,
    remove: (index: number) => void,
    index: number
  ) => {
    if (id) {
      deleteRecommendation(id);
    } else {
      remove(index);
      index === 0 && setFieldValue('recommendations', [emptyItems]);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
        Recommendations
      </Typography>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 2 }}>
        <FieldArray name="recommendations">
          {({ push, remove }) => (
            <>
              {values.recommendations.map(
                ({ title, text, score, id }: RecommendationItem, index: number) => (
                  <Grid key={index} item xs={12} md={4}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="subtitle1" gutterBottom mb={0} mr={2}>
                        {`Recommendation ${index + 1}`}
                      </Typography>
                      <IconButton
                        aria-label="Delete"
                        size="large"
                        disabled={!(title && text && score)}
                        onClick={() => deleteHandler(id, remove, index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                      {id ? (
                        <Button
                          variant="outlined"
                          disabled={!(title && text && score)}
                          onClick={() => updateHandler({ id, title, text, score })}
                        >
                          Edit
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          disabled={!(title && text && score)}
                          onClick={() => createHandler({ title, text, score })}
                        >
                          Add
                        </Button>
                      )}
                    </Box>
                    <Stack spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="Title"
                        {...getFieldProps(`recommendations.[${index}].title`)}
                        error={Boolean(
                          touched.recommendations &&
                            touched?.recommendations[index]?.title &&
                            errors.recommendations &&
                            errors?.recommendations[index]
                            ? (errors.recommendations[index] as FormikErrors<RecommendationItem>)
                                .title
                            : ''
                        )}
                        helperText={
                          touched.recommendations &&
                          touched?.recommendations[index]?.title &&
                          errors.recommendations &&
                          errors?.recommendations[index]
                            ? (errors.recommendations[index] as FormikErrors<RecommendationItem>)
                                .title
                            : ''
                        }
                      />
                      <CountingTextField
                        minRows={8}
                        maxRows={8}
                        limit={250}
                        currentLength={values.recommendations[index].text.length}
                        label="Text"
                        {...getFieldProps(`recommendations.[${index}].text`)}
                        error={Boolean(
                          touched.recommendations &&
                            touched?.recommendations[index]?.text &&
                            errors.recommendations &&
                            errors?.recommendations[index]
                            ? (errors.recommendations[index] as FormikErrors<RecommendationItem>)
                                .text
                            : ''
                        )}
                        helperText={
                          touched.recommendations &&
                          touched?.recommendations[index]?.text &&
                          errors.recommendations &&
                          errors?.recommendations[index]
                            ? (errors.recommendations[index] as FormikErrors<RecommendationItem>)
                                .text
                            : ''
                        }
                      />
                      <Rating
                        value={score || 0}
                        onChange={(event, newValue) => {
                          setFieldValue(`recommendations.[${index}].score`, newValue);
                        }}
                      />
                    </Stack>
                  </Grid>
                )
              )}

              {values.recommendations.length < RECOMMENDATIONS_LIMIT && (
                <Box display="flex" marginLeft={80} width={250} alignItems="center">
                  <Button
                    variant="text"
                    color="primary"
                    disabled={values.recommendations.length !== recommendations.length}
                    onClick={() => push(emptyItems)}
                  >
                    <AddIcon />
                    Add New Recommendation...
                  </Button>
                </Box>
              )}
            </>
          )}
        </FieldArray>
      </Stack>
    </FormikProvider>
  );
};
