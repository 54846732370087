import React from 'react';
import { Box, CircularProgress } from '@mui/material';

type StateWrapperProps = {
  children: React.ReactNode;
  isLoading: boolean;
  width?: string;
};

export const StateWrapper: React.FC<StateWrapperProps> = ({
  children,
  isLoading,
  width = 'auto'
}) => {
  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width
        }}
      >
        <CircularProgress size={50} />
      </Box>
    );
  }
  return <>{children}</>;
};
