import * as React from 'react';
import { Box, Breadcrumbs, Container, Link as MuiLink, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { InviteUserForm } from './InviteUserForm';
import { ROUTES } from '../../../../constants/routes';

export const InviteUser: React.FC = () => {
  return (
    <Container maxWidth="lg" disableGutters sx={{ padding: '30px 24px 0 20px', height: '100%' }}>
      <Box sx={{ mb: 5 }}>
        <Typography variant="h3" gutterBottom>
          Invite User
        </Typography>
        <Breadcrumbs>
          <MuiLink component={Link} to={ROUTES.ADMIN_CONSOLE.ROOT}>
            Dashboard admin
          </MuiLink>
          <MuiLink component={Link} to={ROUTES.ADMIN_CONSOLE.USERS.LIST}>
            Users list
          </MuiLink>
          <Typography>Invite user</Typography>
        </Breadcrumbs>
        <InviteUserForm />
      </Box>
    </Container>
  );
};
