import React from 'react';
import { Box } from '@mui/material';
import { StageColor, TypeColor } from '../../constants/dashboard';
import Chart, { Chart as ChartProps } from 'react-chartjs-2';
import { DashboardListItem } from '../../types/dashboard';
import { VersionItem } from '../../types/version';
import { generateChatData } from '../../utils/chart';
import { fCard } from '../../utils/dateHelpers';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import { Image, StageChip, TypeChip, Code, DateText, Brand } from './CommonCard.styled';

type CommonCardProps = {
  data: DashboardListItem | VersionItem;
};

export const CommonCard: React.FC<CommonCardProps> = ({ data }) => {
  const chartData = generateChatData(data.sustainabilityScore);

  const getStageIcon = (stageId: number): JSX.Element => {
    if (stageId === 3 || stageId === 4) {
      return (
        <FiberManualRecordIcon
          style={{ color: StageColor[stageId], width: '18px', height: '18px' }}
        />
      );
    } else
      return (
        <FiberManualRecordOutlinedIcon
          style={{ color: StageColor[stageId], width: '18px', height: '18px' }}
        />
      );
  };
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <StageChip icon={getStageIcon(data?.stage.id)} label={data?.stage.name} size="small" />
        <TypeChip sx={{ background: TypeColor[data?.type.id] }}>{data.type.name}</TypeChip>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px 0'
        }}
      >
        <Box sx={{ width: '141px', height: '163px' }}>
          <Box
            sx={{
              zIndex: 0,
              width: '100%',
              height: '100%',
              outline: 'none',
              display: 'flex',
              overflow: 'hidden',
              position: 'relative',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '6px'
            }}
          >
            <Image src={data.brandPicture} alt="brand picture" />
          </Box>
        </Box>
        <Box position="relative" width={116} height={116}>
          <Chart
            type="doughnut"
            data={chartData}
            plugins={[
              {
                id: 'doughnut',
                beforeDraw(chart: ChartProps) {
                  const { width, height, ctx } = chart;
                  ctx.restore();
                  ctx.font = `400 17px sans-serif`;
                  ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
                  ctx.textBaseline = 'top';
                  const text: string = `${data.sustainabilityScore}%`;
                  const textX = Math.round((width - ctx.measureText(text).width) / 2);
                  const textY = height / 2.2;
                  ctx.fillText(text, textX, textY);
                  ctx.save();
                }
              }
            ]}
            options={{
              plugins: {
                tooltip: {
                  enabled: false
                }
              },
              layout: {
                padding: {
                  bottom: 5
                }
              },
              cutout: 30
            }}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Code title={`Code #${data.SKU}`}>{`Code #${data.SKU}`}</Code>
        <Brand title={data.brand}>{`${data.brand}`}</Brand>
        <DateText>{`Last updated ${fCard(data.updatedAt)}`}</DateText>
      </Box>
    </>
  );
};
