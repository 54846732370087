import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';

import { Button, TextField as MuiTextField } from '@mui/material';
import { spacing } from '@mui/system';
import useAuth from '../../hooks/useAuth';
import { PasswordInput } from '../PasswordInput';
import { signInRules } from './rules';

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const DEFAULT_VALUES = {
  email: '',
  password: '',
  submit: false
};

type SignInValues = {
  email: string;
  password: string;
  submit: boolean;
};

const SignIn: React.FC = () => {
  const { signIn } = useAuth();

  const { errors, handleBlur, handleChange, handleSubmit, touched, values } =
    useFormik<SignInValues>({
      initialValues: DEFAULT_VALUES,
      validationSchema: signInRules,
      validateOnChange: true,
      onSubmit: async (values) => {
        signIn(values.email, values.password);
      }
    });

  return (
    <form noValidate onSubmit={handleSubmit}>
      <TextField
        type="email"
        name="email"
        label="Email Address"
        value={values.email}
        error={Boolean(touched.email && errors.email)}
        fullWidth
        helperText={touched.email && errors.email}
        onBlur={handleBlur}
        onChange={handleChange}
        my={2}
      />
      <PasswordInput
        value={values.password}
        name="password"
        label="Password"
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(touched.password && errors.password)}
        helperText={touched.password && errors.password}
        my={2}
      />

      <Button type="submit" fullWidth variant="contained" color="primary">
        Log in
      </Button>
      <Button component={Link} to="/auth/forgot-password" fullWidth color="primary">
        Forgot password
      </Button>
      <Button component={Link} to="/auth/sign-up" fullWidth color="primary">
        Create account
      </Button>
    </form>
  );
};

export default SignIn;
