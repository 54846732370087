import { Provider } from 'react-redux';
import { create } from 'jss';
import { ThemeProvider } from 'styled-components/macro';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import jssPreset from '@mui/styles/jssPreset';

import createTheme from './theme';

import useTheme from './hooks/useTheme';
import { setupStore } from './redux/store';

import { AuthProvider } from './contexts/JWTContext';
import Router from './routes';
import NotistackProvider from './components/NotistackProvider';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point')!
});
const store = setupStore();

function App() {
  const { theme } = useTheme();
  return (
    <Provider store={store}>
      <StylesProvider jss={jss}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={createTheme(theme)}>
            <ThemeProvider theme={createTheme(theme)}>
              <NotistackProvider>
                <AuthProvider>
                  <Router />
                </AuthProvider>
              </NotistackProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </StylesProvider>
    </Provider>
  );
}

export default App;
