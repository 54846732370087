import useAuth from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import { PATH_PAGES } from '../../constants';
import { ReactNode } from 'react';
import { UserRoles } from '../../constants/roles';

interface AdminGuardType {
  children: ReactNode;
}

function AdminGuard({ children }: AdminGuardType) {
  const { user } = useAuth();
  if (user?.role === UserRoles.USER) {
    return <Navigate to={PATH_PAGES.ROOT} />;
  }

  return <>{children}</>;
}

export default AdminGuard;
