import React, { useCallback, useEffect, useState } from 'react';
import { Box, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { dashboardAPI } from '../../../services';
import { ErrorCodes } from '../../../constants/errors';
import useAuth from '../../../hooks/useAuth';
import { MainError } from '../../../types/common';
import { useAppSelector } from '../../../hooks/useReduxHelper';
import { Item } from './Item';
import { ROUTES } from '../../../constants/routes';
import { ListToolbar } from './ListToolbar';
import { DashboardListItem } from '../../../types/dashboard';
import { StateWrapper } from '../../../components/StateWrapper';
import { scrollToTop } from '../../../utils/helpers';
import { RootStyle, ListWrapper, Text } from './List.styled';

const DEFAULT_DATA = {
  dashboards: [],
  total: 0
};

const LIMIT = 12;
const DEFAULT_PAGE = 1;

export const List: React.FC = (): JSX.Element => {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [searchValue, setSearchValue] = useState<string>('');

  const { signOut } = useAuth();
  const navigate = useNavigate();

  const { brands, stages, types } = useAppSelector((state) => state.filtersSlicer);
  const { orderBy, sortBy } = useAppSelector((state) => state.sortingSlicer);

  useEffect(() => {
    setPage(DEFAULT_PAGE);
    scrollToTop();
  }, [brands, stages, types, orderBy, sortBy]);

  const {
    data = DEFAULT_DATA,
    error,
    isFetching
  } = dashboardAPI.useFetchAllDashboardsQuery(
    {
      page,
      limit: LIMIT,
      searchTerm: searchValue,
      orderBy,
      sortBy,
      brands: brands.length ? `[${brands}]` : '',
      stages: stages.length ? `[${stages}]` : '',
      types: types.length ? `[${types}]` : ''
    },
    { refetchOnMountOrArgChange: true }
  );

  const onSearch = useCallback((newValue: string) => {
    setSearchValue(newValue);
  }, []);

  useEffect(() => {
    const errorData = error as MainError;

    if (errorData?.data?.statusCode === ErrorCodes.UNAUTHORIZED) {
      signOut();
      setPage(0);
    }
  }, [error, signOut]);

  const onPageChange = useCallback((event, page) => {
    setPage(page);
    scrollToTop();
  }, []);

  const pagesCount = Math.ceil(data.total / LIMIT);

  return (
    <RootStyle>
      <ListToolbar searchValue={searchValue} onSearch={onSearch} />
      <StateWrapper isLoading={isFetching}>
        {data.dashboards.length ? (
          <>
            <ListWrapper>
              {data.dashboards.map((dashboard: DashboardListItem, index) => (
                <Item
                  key={index}
                  data={dashboard}
                  onClick={() =>
                    navigate(`${ROUTES.DASHBOARDS.ROOT}/${dashboard.id}${ROUTES.VERSIONS.ROOT}`)
                  }
                />
              ))}
            </ListWrapper>
            <Pagination
              page={page}
              count={pagesCount}
              shape="rounded"
              onChange={onPageChange}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '16px',
                paddingRight: '20px'
              }}
            />
          </>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
            <Text>There are no results</Text>
          </Box>
        )}
      </StateWrapper>
    </RootStyle>
  );
};
