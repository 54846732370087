import { Navigate, useRoutes } from 'react-router-dom';

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from './layouts/Auth';
import PresentationLayout from './layouts/Presentation';
import { DashboardLayout } from './layouts/Dashboard';
// Guards
import AuthGuard from './components/guards/AuthGuard';
import GuestGuard from './components/guards/GuestGuard';
import AdminGuard from './components/guards/AdminGuard';
// Auth components
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import Page404 from './pages/auth/Page404';
import Page500 from './pages/auth/Page500';
import SendCode from './pages/auth/SendCode';
import InviteRegister from './pages/auth/InviteRegister';
import ResetPassword from './pages/auth/ResetPassword';
import ForgotPassword from './pages/auth/ForgotPassword';
import ConfirmUpdateEmail from './pages/auth/ConfirmUpdateEmail';

import { Dashboards } from './pages/dashboards';
import { ImpactDashboard } from './pages/impactDashboard';
import { Versions } from './pages/versions';
import { ROUTES } from './constants/routes';
import { EditVersion } from './pages/editVersion';
import { ConfirmCode } from './pages/auth/ConfirmCode';
import { AdminConsole } from './pages/adminConsole/AdminConsole/AdminConsole';
import { DashboardsListPage } from './pages/adminConsole/Dashboards/DashboardListPage';
import { CreateBrandDashboard } from './pages/adminConsole/Dashboards/Create/CreateBrandDashboard';
import { EditBrandDashboard } from './pages/adminConsole/Dashboards/Edit/EditBrandDashboard';
import { UsersManagementListPage } from './pages/adminConsole/UserManagement/UserManagementListPage';
import EditUser from './pages/adminConsole/UserManagement/Edit/EditUser';
import { InviteUser } from './pages/adminConsole/UserManagement/Invite/InviteUser';

function Router() {
  return useRoutes([
    {
      path: 'auth',
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: 'sign-in',
          element: <SignIn />
        },
        {
          path: 'sign-up',
          element: <SignUp />
        },
        {
          path: 'register',
          element: <InviteRegister />
        },
        {
          path: 'reset-password',
          element: <ResetPassword />
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />
        },
        {
          path: 'update-email',
          element: <ConfirmUpdateEmail />
        },
        {
          path: 'send-2fa',
          element: <SendCode />
        },
        {
          path: 'send-2fa/code',
          element: <ConfirmCode />
        }
      ]
    },
    {
      path: '*',
      element: <AuthLayout />,
      children: [
        {
          path: '404',
          element: <Page404 />
        },
        {
          path: '500',
          element: <Page500 />
        },
        {
          path: '*',
          element: <Navigate to="/404" />
        }
      ]
    },
    {
      path: ROUTES.ROOT,
      element: (
        <AuthGuard>
          <PresentationLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ROUTES.ROOT,
          element: <DashboardLayout />,
          children: [
            {
              path: ROUTES.ROOT,
              element: <Dashboards />
            },
            {
              path: `${ROUTES.DASHBOARDS.ROOT}/:id${ROUTES.VERSIONS.ROOT}`,
              element: <Versions />
            }
          ]
        },
        {
          path: `${ROUTES.DASHBOARDS.ROOT}/:id${ROUTES.VERSIONS.ROOT}/:versionId`,
          element: <ImpactDashboard />
        },
        {
          path: `${ROUTES.DASHBOARDS.ROOT}/:id${ROUTES.VERSIONS.ROOT}/:versionId${ROUTES.VERSIONS.EDIT}`,
          element: <EditVersion />
        }
      ]
    },
    {
      path: ROUTES.ADMIN_CONSOLE.ROOT,
      element: (
        <AuthGuard>
          <AdminGuard>
            <PresentationLayout />
          </AdminGuard>
        </AuthGuard>
      ),
      children: [
        { path: ROUTES.ADMIN_CONSOLE.ROOT, element: <AdminConsole /> },
        { path: ROUTES.ADMIN_CONSOLE.DASHBOARDS.LIST, element: <DashboardsListPage /> },
        { path: ROUTES.ADMIN_CONSOLE.DASHBOARDS.ADD, element: <CreateBrandDashboard /> },
        {
          path: `${ROUTES.ADMIN_CONSOLE.DASHBOARDS.ROOT}/:id${ROUTES.ADMIN_CONSOLE.DASHBOARDS.EDIT}`,
          element: <EditBrandDashboard />
        },
        { path: ROUTES.ADMIN_CONSOLE.USERS.LIST, element: <UsersManagementListPage /> },
        { path: ROUTES.ADMIN_CONSOLE.USERS.INVITE, element: <InviteUser /> },
        {
          path: `${ROUTES.ADMIN_CONSOLE.USERS.ROOT}/:id${ROUTES.ADMIN_CONSOLE.USERS.EDIT}`,
          element: <EditUser />
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

export default Router;
