import React, { SyntheticEvent, useCallback, useRef, useState } from 'react';
import { Box, Paper, Typography, IconButton } from '@mui/material';
import { Star, Edit, Copy, Trash2 } from 'react-feather';
import { VersionItem as VersionItemType } from '../../../types/version';
import { CommonCard } from '../../../components/CommonCard';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { dashboardAPI } from '../../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { ShowMoreIcon } from '../../../assets/icons/ShowMore';
import { ArrowStyle, Row, RowText, ToolBar } from './VersionItem.styled';

type VersionItemProps = {
  data: VersionItemType;
  onClick: () => void;
  lastVersionNumber: number;
  onDeleteVersion: (id: number) => void;
};

export const VersionItem: React.FC<VersionItemProps> = ({
  data,
  onClick,
  lastVersionNumber,
  onDeleteVersion
}): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  const [isShowedToolbar, setIsShowedToolbar] = useState<boolean>(false);

  const starColor = data.isPrimary ? '#FDA228' : '#EAECF6';

  useClickOutside(ref, () => setIsShowedToolbar(false));

  const [copyVersion] = dashboardAPI.useCopyVersionMutation();
  const [updatePrimaryPointer] = dashboardAPI.useUpdatePrimaryPointerMutation();

  const handleCopy = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      copyVersion({
        id: data.id,
        lastRowNumber: lastVersionNumber
      });
      setIsShowedToolbar(false);
    },
    [copyVersion, data.id, lastVersionNumber]
  );

  const handleEdit = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      navigate(
        `${ROUTES.DASHBOARDS.ROOT}/${id}${ROUTES.VERSIONS.ROOT}/${data.id}${ROUTES.VERSIONS.EDIT}`
      );
    },
    [data.id, id, navigate]
  );

  const handleDelete = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      onDeleteVersion(data.id);
      setIsShowedToolbar(false);
    },
    [data.id, onDeleteVersion]
  );

  const handleUpdatePrimaryPointer = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      updatePrimaryPointer(data.id);
      setIsShowedToolbar(false);
    },
    [updatePrimaryPointer, data.id, setIsShowedToolbar]
  );

  const handleShowMoreIconClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setIsShowedToolbar((prevState) => !prevState);
  }, []);

  return (
    <Paper
      sx={{
        borderRadius: '8px',
        minWidth: '300px',
        width: '316px',
        minHeight: '357px',
        height: 'auto',
        margin: '10px',
        padding: '6px 18px 14px 18px',
        cursor: 'pointer',
        boxShadow: '1px 2px 10px -1px rgba(0, 0, 0, 0.1)'
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
          position: 'relative'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative'
          }}
        >
          <Star fill={starColor} color={starColor} height={16} width={16} />
          <Typography sx={{ fontSize: 16, ml: '8px' }}>{data.version}</Typography>
        </Box>
        <Box ref={ref}>
          <IconButton
            sx={{
              position: 'relative'
            }}
            onClick={handleShowMoreIconClick}
          >
            <ShowMoreIcon />
          </IconButton>
          {isShowedToolbar && (
            <ToolBar>
              <ArrowStyle />
              {!data.isPrimary && (
                <Row onClick={handleUpdatePrimaryPointer}>
                  <Star height={16} width={16} />
                  <RowText sx={{ fontSize: 14, ml: '8px' }}>Make Primary</RowText>
                </Row>
              )}
              <Row onClick={handleEdit}>
                <Edit height={16} width={16} />
                <RowText sx={{ fontSize: 14 }}>Edit</RowText>
              </Row>
              <Row onClick={handleCopy}>
                <Copy height={16} width={16} />
                <RowText sx={{ fontSize: 14 }}>Copy</RowText>
              </Row>
              <Row onClick={handleDelete}>
                <Trash2 height={16} width={16} />
                <RowText sx={{ fontSize: 14 }}>Delete</RowText>
              </Row>
            </ToolBar>
          )}
        </Box>
      </Box>
      <CommonCard data={data} />
    </Paper>
  );
};
