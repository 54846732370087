import React from 'react';
import styled from 'styled-components/macro';

import { Paper, Typography } from '@mui/material';

import ForgotPasswordComponent from '../../components/auth/ForgotPassword';

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  width: 100%;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
const ForgotPassword: React.FC = () => {
  return (
    <React.Fragment>
      <Wrapper>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Forgot Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          We’ll send you a link to reset your password
        </Typography>

        <ForgotPasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
};

export default ForgotPassword;
