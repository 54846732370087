import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { DashboardListItem } from '../../../types/dashboard';
import { CommonCard } from '../../../components/CommonCard';

type ItemProps = {
  data: DashboardListItem;
  onClick: () => void;
};

const MAX_CARD_WIDTH = 316;
const DEFAULT_CARD_INDENT = 6;
const MAX_CARD_VERSIONS_COUNT = 5;

const DEFAULT_BOX_SHADOW = '0px 2px 7px rgba(46, 56, 77, 0.11)';
const MAXIMUM_BOX_SHADOW =
  '0px 2px 7px rgba(46, 56, 77, 0.11), 6px 6px 1px #fff, 6px 8px 7px rgba(46, 56, 77, 0.11), 12px 12px 1px #fff, 12px 14px 7px rgba(46, 56, 77, 0.11), 18px 18px 1px #fff, 18px 20px 7px rgba(46, 56, 77, 0.11), 24px 24px 1px #fff, 24px 26px 7px rgba(46, 56, 77, 0.11)';

export const Item: React.FC<ItemProps> = ({ data, onClick }): JSX.Element => {
  const { versionCount } = data;

  const [cardBoxShadow, setCardBoxShadow] = useState<string>(DEFAULT_BOX_SHADOW);

  useEffect(() => {
    if (versionCount && versionCount !== 0 && versionCount < MAX_CARD_VERSIONS_COUNT) {
      let newBoxShadow: string = DEFAULT_BOX_SHADOW;

      for (let i = 1; i <= versionCount; i++) {
        newBoxShadow = `${newBoxShadow}, ${i * DEFAULT_CARD_INDENT}px ${
          i * DEFAULT_CARD_INDENT
        }px 1px #fff, ${i * DEFAULT_CARD_INDENT}px ${
          i * DEFAULT_CARD_INDENT + 2
        }px 7px rgba(46, 56, 77, 0.11)`;
      }

      setCardBoxShadow(newBoxShadow);
    } else if (versionCount && versionCount >= MAX_CARD_VERSIONS_COUNT) {
      setCardBoxShadow(MAXIMUM_BOX_SHADOW);
    }
  }, [cardBoxShadow, versionCount]);

  return (
    <Box
      sx={{
        borderRadius: '8px',
        width: '316px',
        minHeight: '320px',
        height: 'auto',
        margin: '0 20px 0 0'
      }}
    >
      <Paper
        sx={{
          borderRadius: '8px',
          width: versionCount ? MAX_CARD_WIDTH - versionCount * DEFAULT_CARD_INDENT : '316px',
          minWidth: '292px',
          minHeight: '320px',
          marginBottom: '40px',
          height: 'auto',
          padding: '18px',
          cursor: 'pointer',
          boxShadow: cardBoxShadow
        }}
        onClick={onClick}
      >
        <CommonCard data={data} />
      </Paper>
    </Box>
  );
};
