import * as React from 'react';
import styled from 'styled-components/macro';
import { Link, useParams } from 'react-router-dom';
import { spacing } from '@mui/system';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Link as MuiLink,
  Container
} from '@mui/material';
import { dashboardAPI } from '../../services';
import { ROUTES } from '../../constants/routes';
import { useCallback } from 'react';
import { DashboardForm } from '../../components/DashboardForm/DashboardForm';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const EditVersion: React.FC = (): JSX.Element => {
  const { versionId = '', id = '' } = useParams();

  const { data, isLoading } = dashboardAPI.useFetchDashboardByIdQuery(
    { id: versionId },
    { refetchOnMountOrArgChange: true }
  );

  const [
    updateDashboard,
    { isSuccess: isSuccessUpdate, error: updateError, isLoading: isLoadingUpdate }
  ] = dashboardAPI.useUpdateVersionMutation();

  const handleRequestUpdate = useCallback(
    (values: FormData) => {
      updateDashboard(values);
    },
    [updateDashboard]
  );

  const redirectingRoute = `${ROUTES.DASHBOARDS.ROOT}/${id}${ROUTES.VERSIONS.ROOT}`;

  return (
    <Container disableGutters maxWidth="xl" sx={{ padding: '30px 24px 80px 20px' }}>
      <Typography variant="h3" gutterBottom>
        Edit Version
      </Typography>
      <Breadcrumbs>
        <MuiLink component={Link} to={ROUTES.ROOT}>
          Dashboard cards
        </MuiLink>
        <MuiLink component={Link} to={redirectingRoute}>
          Versions
        </MuiLink>
        <Typography>Edit version</Typography>
      </Breadcrumbs>
      <DashboardForm
        dashboardId={versionId}
        data={data}
        isLoading={isLoading}
        handleRequest={handleRequestUpdate}
        isSuccessRequest={isSuccessUpdate}
        error={updateError}
        isLoadingRequest={isLoadingUpdate}
        route={redirectingRoute}
      />
    </Container>
  );
};
