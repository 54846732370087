import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

export const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: theme.breakpoints.down('md') ? '100%' : '428px',
  minHeight: '380px',
  height: 'auto',
  padding: '20px 24px 23px 24px',
  borderRadius: '8px',
  backgroundColor: '#2E384D'
}));

export const Row = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px'
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginRight: '10px',
  fontSize: '18px',
  fontFamily: 'Helvetica',
  fontStyle: 'normal',
  lineHeight: '21px',
  fontWeight: 700,
  color: '#FFFFFF'
}));

export const ChartWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '16px'
}));

export const Text = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  width: '100%',
  maxWidth: '300px',
  marginBottom: '14px',
  color: '#FFFFFF',
  fontSize: '14px',
  fontFamily: 'Helvetica',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '16px'
}));

export const Conclusion = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  width: '100%',
  maxWidth: '300px',
  color: '#FFFFFF',
  fontSize: '14px',
  fontFamily: 'Helvetica',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '16px'
}));

export const InfoText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Helvetica',
  fontStyle: 'normal',
  fontSize: '12px',
  fontWeight: '300',
  lineHeight: '14px',
  color: 'rgba(0, 0, 0, 0.8)'
}));

export const ArrowStyle = styled('span')<{ top: string; right: string }>(
  ({ theme, top, right }) => ({
    top: top,
    zIndex: 1,
    width: 20,
    right: right,
    height: 20,
    content: "''",
    position: 'absolute',
    borderRadius: '3px',
    transform: 'rotate(-135deg)',
    background: theme.palette.background.paper
  })
);

export const ToolBar = styled('div')<{ transform?: string }>(({ theme, transform }) => ({
  position: 'absolute',
  width: '175px',
  height: '131px',
  borderRadius: '6px',
  zIndex: 10,
  background: 'white',
  pointerEvents: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 16px',
  transform: transform
}));
