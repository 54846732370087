import React from 'react';
import styled from 'styled-components/macro';
import useAuth from '../../../hooks/useAuth';
import { Box, Button, Container, Grid, Link as MuiLink, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { StateWrapper } from '../../../components/StateWrapper';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center'
}));

export const AdminConsole: React.FC = () => {
  const { isInitialized } = useAuth();

  return (
    <RootStyle>
      <StateWrapper isLoading={!isInitialized}>
        <Container maxWidth="lg">
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            Welcome to the Impact Dashboard Admin Console
          </Typography>
          <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
            Please select one of the following...
          </Typography>
          <Box sx={{ mt: 5 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={10} sm={6}>
                <MuiLink
                  component={Link}
                  to={ROUTES.ADMIN_CONSOLE.DASHBOARDS.ADD}
                  underline={'none'}
                >
                  <Button fullWidth size="large" type="button" variant="contained">
                    Add new dashboard
                  </Button>
                </MuiLink>
              </Grid>
              <Grid item xs={10} sm={6}>
                <MuiLink
                  component={Link}
                  to={ROUTES.ADMIN_CONSOLE.DASHBOARDS.LIST}
                  underline={'none'}
                >
                  <Button fullWidth size="large" type="button" variant="contained">
                    Edit existing dashboard
                  </Button>
                </MuiLink>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={10} sm={6} sx={{ mt: '8px' }}>
                <Button fullWidth size="large" type="button" variant="contained">
                  View dashboard
                </Button>
              </Grid>
              <Grid item xs={10} sm={6} sx={{ mt: '8px' }}>
                <MuiLink component={Link} to={ROUTES.ADMIN_CONSOLE.USERS.LIST} underline={'none'}>
                  <Button fullWidth size="large" type="button" variant="contained">
                    User management
                  </Button>
                </MuiLink>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </StateWrapper>
    </RootStyle>
  );
};
