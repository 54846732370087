import * as React from 'react';
import styled from 'styled-components/macro';
import { Link, useParams } from 'react-router-dom';
import { spacing } from '@mui/system';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Box,
  Link as MuiLink,
  Container
} from '@mui/material';
import { ROUTES } from '../../../../constants/routes';
import { DashboardForm } from '../../../../components/DashboardForm/DashboardForm';
import { dashboardAPI } from '../../../../services';
import { useCallback } from 'react';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const CreateBrandDashboard: React.FC = () => {
  const { id = '' } = useParams();

  const [
    createDashboard,
    { isSuccess: isSuccessCreate, error: createError, isLoading: isLoadingCreate }
  ] = dashboardAPI.useCreateDashboardMutation();

  const handleRequestCreate = useCallback(
    (form: FormData) => {
      createDashboard(form);
    },
    [createDashboard]
  );

  return (
    <Container maxWidth="lg" sx={{ paddingTop: '30px' }}>
      <Box sx={{ mb: 5 }}>
        <Typography variant="h3" gutterBottom>
          Add Brand Dashboard
        </Typography>
        <Breadcrumbs>
          <MuiLink component={Link} to={ROUTES.ADMIN_CONSOLE.ROOT}>
            Dashboard admin
          </MuiLink>
          <MuiLink component={Link} to={ROUTES.ADMIN_CONSOLE.DASHBOARDS.LIST}>
            List
          </MuiLink>
          <Typography>New dashboard</Typography>
        </Breadcrumbs>
        <DashboardForm
          dashboardId={id}
          handleRequest={handleRequestCreate}
          isSuccessRequest={isSuccessCreate}
          error={createError}
          isLoadingRequest={isLoadingCreate}
          route={ROUTES.ADMIN_CONSOLE.DASHBOARDS.LIST}
        />
      </Box>
    </Container>
  );
};
