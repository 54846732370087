import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components/macro';

import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { THEMES } from '../constants';
import createTheme from '../theme';

import GlobalStyle from '../components/GlobalStyle';
import Footer from '../components/Footer';
import { LeftSidebar } from '../components/LeftSidebar';
import { useEffect } from 'react';

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding-left: 72px;
  min-height: 100%;
`;

const Presentation: React.FC = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    document?.getElementById('root')?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <MuiThemeProvider theme={createTheme(THEMES.DEFAULT)}>
      <ThemeProvider theme={createTheme(THEMES.DEFAULT)}>
        <Root>
          <CssBaseline />
          <GlobalStyle />
          <AppContent>
            <LeftSidebar />
            {children}
            <Outlet />
            <Footer />
          </AppContent>
        </Root>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default Presentation;
