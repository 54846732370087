import React from 'react';
import { RootStyle, Title } from './ProductDetail.styled';
import { InfoBlock } from './InfoBlock';
import {
  DashboardItem,
  DistributionTerritoryItem,
  ProductionLocationItem
} from '../../../types/dashboard';
import { makeStringFromArray } from '../../../utils/dashboard';

type ProductDetailProps = {
  data: DashboardItem;
};

export const ProductDetail: React.FC<ProductDetailProps> = ({ data }): JSX.Element => {
  return (
    <RootStyle>
      <Title>Product Detail</Title>
      <InfoBlock label="Design Origin" text={data.designOrigin} />
      <InfoBlock label="Sustainability Optimisation" text={data.sustainabilityOptimisation} />
      <InfoBlock label="Component Count" text={data.componentCount} />
      <InfoBlock label="Material Count" text={data.materialCount} />
      <InfoBlock label="Material List" text={makeStringFromArray(data.materialLists, 'name')} />
      <InfoBlock label="Estimated production quantity" text={data.estimatedProductQuantity} />
      <InfoBlock
        label="Production Location"
        text={makeStringFromArray<ProductionLocationItem>(data.productionLocations, 'countryName')}
      />
      <InfoBlock
        label="Territories distributed to"
        text={makeStringFromArray<DistributionTerritoryItem>(
          data.distributionTerritories,
          'continentName'
        )}
      />
      <InfoBlock label="Packed Dimensions" text={data.packedDimensions} />
    </RootStyle>
  );
};
