import React, { useRef } from 'react';
import { Info as InfoIcon } from 'react-feather';
import Chart, { Chart as ChartProps } from 'react-chartjs-2';
import { Box, Divider, Stack } from '@mui/material';
import {
  RootStyle,
  Row,
  Title,
  ChartWrapper,
  Text,
  Conclusion,
  InfoText,
  ToolBar,
  ArrowStyle
} from './SustainabilityScore.styled';
import { generateChatData } from '../../../utils/chart';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type SustainabilityScoreProps = {
  score: number;
};

const ProgressColumnStyles = {
  width: '8px',
  height: '93px',
  zIndex: 5,
  background: 'linear-gradient(#6CD151, #FFD200, #FF6501, #FF1500)'
};

export const SustainabilityScore: React.FC<SustainabilityScoreProps> = ({ score }): JSX.Element => {
  const data = generateChatData(score);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const ref = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handlePopoverOpen = () => {
    setIsOpen(true);
  };

  const handlePopoverClose = () => {
    setIsOpen(false);
  };

  return (
    <RootStyle>
      <Row>
        <Title>Sustainability score</Title>
        <Box
          ref={ref}
          onMouseEnter={handlePopoverOpen}
          onMouseOut={handlePopoverClose}
          height={14}
          width={14}
        >
          <InfoIcon color="white" style={{ pointerEvents: 'none' }} height={14} width={14} />
        </Box>
        {isOpen && (
          <ToolBar
            transform={matches ? 'translate(204px, 41px)' : 'translate(69px, 84px)'}
            style={{ justifyContent: 'start' }}
          >
            <ArrowStyle top={matches ? '14px' : '-7px'} right={matches ? '161px' : '47px'} />
            <Box sx={ProgressColumnStyles} />
            <Stack direction="column" spacing={3.5}>
              <Stack direction="row" alignItems="center">
                <Divider sx={{ width: '8px', background: 'black', margin: '0 8px' }} />
                <InfoText>Excellent 75-100%</InfoText>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Divider sx={{ width: '8px', background: 'black', margin: '0 8px' }} />
                <InfoText>Reasonable 50-75%</InfoText>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Divider sx={{ width: '8px', background: 'black', margin: '0 8px' }} />
                <InfoText>Problematic 25-50%</InfoText>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Divider sx={{ width: '8px', background: 'black', margin: '0 8px' }} />
                <InfoText>Poor 0-25%</InfoText>
              </Stack>
            </Stack>
          </ToolBar>
        )}
      </Row>
      <ChartWrapper>
        <Box position="relative" width={181} height={181}>
          <Chart
            type="doughnut"
            data={data}
            plugins={[
              {
                id: 'doughnut',
                beforeDraw(chart: ChartProps) {
                  const { width, height, ctx } = chart;

                  ctx.restore();
                  ctx.font = `bold 29.6491px sans-serif`;
                  ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
                  ctx.textBaseline = 'top';

                  const text: string = `${score}%`;
                  const textX = Math.round((width - ctx.measureText(text).width) / 2);
                  const textY = height / 2.3;

                  ctx.fillText(text, textX, textY);
                  ctx.save();
                }
              }
            ]}
            options={{
              borderColor: '#2E384D',
              plugins: {
                tooltip: {
                  enabled: false
                }
              },
              layout: {
                padding: {
                  bottom: 5
                }
              },
              cutout: 63
            }}
          />
        </Box>
      </ChartWrapper>
      <Text>
        The overall Impact Score has been generated using the information analysed by Pentatonic
        engineers and material specs provided which has been aggregated into one overall grading.
      </Text>
      <Conclusion>Scores under 50% are strongly recommended for reconsideration.</Conclusion>
    </RootStyle>
  );
};
