import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { X } from 'react-feather';
import { Box, IconButton } from '@mui/material';
import { dashboardAPI } from '../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { IMPACT_DASHBOARD_DEFAULT_DATA } from '../../constants/dashboard';
import { VersionItem } from './VersionItem';
import { StateWrapper } from '../../components/StateWrapper';
import { ROUTES } from '../../constants/routes';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  width: 'calc(100% - 316px)',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}));

export const Versions: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data = IMPACT_DASHBOARD_DEFAULT_DATA, isLoading: isLoadingDashboard } =
    dashboardAPI.useFetchDashboardByIdQuery({ id }, { skip: !id });

  const { data: versions = [], isLoading: isLoadingVersions } = dashboardAPI.useFetchVersionsQuery(
    { primaryPointer: data?.primaryPointer || '' },
    { skip: !data?.primaryPointer }
  );

  const [deleteVersion] = dashboardAPI.useDeleteVersionMutation();

  const lastVersionNumber = versions[versions.length - 1]?.versionRowNumber;

  const onDeleteVersion = useCallback(
    async (id: number) => {
      const { nextPrimaryDashboardId } = await deleteVersion(id).unwrap();

      if (nextPrimaryDashboardId) {
        navigate(`${ROUTES.DASHBOARDS.ROOT}/${nextPrimaryDashboardId}${ROUTES.VERSIONS.ROOT}`);
      }

      if (versions.length === 1) {
        navigate(ROUTES.ROOT);
      }
    },
    [deleteVersion, navigate, versions.length]
  );

  useEffect(() => {
    document?.getElementById('root')?.scrollTo(0, 0);
  }, []);

  return (
    <StateWrapper isLoading={isLoadingVersions || isLoadingDashboard} width="100%">
      <RootStyle>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: '24px' }}>
          <IconButton
            sx={{ background: 'white', boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.1)' }}
            onClick={() => navigate(ROUTES.ROOT)}
          >
            <X />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingLeft: '10px' }}>
          {versions?.map((version) => (
            <VersionItem
              key={`version-item-${version.id}`}
              data={version}
              onClick={() =>
                navigate(`${ROUTES.DASHBOARDS.ROOT}/${id}${ROUTES.VERSIONS.ROOT}/${version.id}`)
              }
              lastVersionNumber={lastVersionNumber}
              onDeleteVersion={onDeleteVersion}
            />
          ))}
        </Box>
      </RootStyle>
    </StateWrapper>
  );
};
