import { SvgIcon, SvgIconProps } from '@mui/material';

export const ShowMoreIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 18 20" sx={{ fontSize: '1.25rem' }} {...props}>
    <path
      d="M0.00195316 9.99991C0.00195317 8.54022 1.18932 7.35285 2.64901 7.35285C4.10871 7.35285 5.29607 8.54022 5.29607 9.99991C5.29607 11.4596 4.10871 12.647 2.64901 12.647C1.18932 12.647 0.00195314 11.4596 0.00195316 9.99991ZM6.35489 9.99991C6.35489 8.54022 7.54226 7.35285 9.00195 7.35285C10.4616 7.35285 11.649 8.54022 11.649 9.99991C11.649 11.4596 10.4616 12.647 9.00195 12.647C7.54226 12.647 6.35489 11.4596 6.35489 9.99991ZM12.7078 9.99991C12.7078 8.54022 13.8952 7.35285 15.3549 7.35285C16.8146 7.35285 18.002 8.54022 18.002 9.99991C18.002 11.4596 16.8146 12.647 15.3549 12.647C13.8952 12.647 12.7078 11.4596 12.7078 9.99991Z"
      fill="#18191E"
    />
  </SvgIcon>
);
