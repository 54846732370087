import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

export const ToolBar = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '167px',
  height: 'auto',
  borderRadius: '8px',
  transform: 'translate(-126px, 6px)',
  zIndex: 10,
  background: 'white',
  filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.11))'
}));

export const ArrowStyle = styled('span')(({ theme }) => ({
  top: '-7px',
  zIndex: '-1',
  width: 14,
  right: '15px',
  height: 14,
  content: "''",
  position: 'absolute',
  borderRadius: '3px',
  transform: 'rotate(45deg)',
  background: theme.palette.background.paper
}));

export const Row = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 0 8px 18px',
  color: 'black',
  cursor: 'pointer',
  '&:hover': {
    background: '#F0F2F7',
    borderRadius: '8px'
  }
}));

export const RowText = styled(Typography)(() => ({
  fontSize: 14,
  marginLeft: '8px'
}));
