import React from 'react';
import { Label, TextInfo } from './ProductDetail.styled';
import { Box } from '@mui/material';

type InfoBlockProps = {
  label: string;
  text: string | number;
};

export const InfoBlock: React.FC<InfoBlockProps> = ({ label, text }): JSX.Element => {
  return (
    <Box mb="16px">
      <Label>{label}</Label>
      <TextInfo>{text}</TextInfo>
    </Box>
  );
};
