import { SvgIcon, SvgIconProps } from '@mui/material';

export const MenuIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M23.1504 0.992188H0.860263C0.735165 0.992188 0.632812 1.09454 0.632812 1.21964V3.03924C0.632812 3.16434 0.735165 3.26669 0.860263 3.26669H23.1504C23.2755 3.26669 23.3778 3.16434 23.3778 3.03924V1.21964C23.3778 1.09454 23.2755 0.992188 23.1504 0.992188ZM23.1504 18.7333H0.860263C0.735165 18.7333 0.632812 18.8357 0.632812 18.9608V20.7804C0.632812 20.9055 0.735165 21.0078 0.860263 21.0078H23.1504C23.2755 21.0078 23.3778 20.9055 23.3778 20.7804V18.9608C23.3778 18.8357 23.2755 18.7333 23.1504 18.7333ZM23.1504 9.86275H0.860263C0.735165 9.86275 0.632812 9.9651 0.632812 10.0902V11.9098C0.632812 12.0349 0.735165 12.1373 0.860263 12.1373H23.1504C23.2755 12.1373 23.3778 12.0349 23.3778 11.9098V10.0902C23.3778 9.9651 23.2755 9.86275 23.1504 9.86275Z"
      fill="inherit"
    />
  </SvgIcon>
);
