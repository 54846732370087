import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { dashboardAPI } from '../services';
import { autocompleteAPI } from '../services';
import { companyAPI } from '../services';
import filtersSlicer from './slices/FiltersSlice';
import sortingSlicer from './slices/SortingSlice';
import { userManagementAPI } from '../services/UserManagementService';

const rootReducer = combineReducers({
  [dashboardAPI.reducerPath]: dashboardAPI.reducer,
  [companyAPI.reducerPath]: companyAPI.reducer,
  [autocompleteAPI.reducerPath]: autocompleteAPI.reducer,
  [userManagementAPI.reducerPath]: userManagementAPI.reducer,
  filtersSlicer,
  sortingSlicer
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        dashboardAPI.middleware,
        companyAPI.middleware,
        autocompleteAPI.middleware,
        userManagementAPI.middleware
      ])
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
