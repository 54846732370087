import { useFormik } from 'formik';
import React, { SyntheticEvent, useEffect } from 'react';
import { Autocomplete, Box, Button, Card, Grid, Stack, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { rules } from './rules';
import { useSnackbar } from 'notistack';
import { User } from '../../../../types/user';
import { useUpdateUserMutation } from '../../../../services/UserManagementService';
import { ROUTES } from '../../../../constants/routes';
import { StateWrapper } from '../../../../components/StateWrapper';
import { ROLES } from '../../../../constants/roles';

type UserFormProps = {
  data?: User;
  isLoading: boolean;
};

type UserInitialValues = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
};

const DEFAULT_DATA = {
  id: -1,
  firstName: '',
  lastName: '',
  email: '',
  role: ''
};

export const UserForm: React.FC<UserFormProps> = ({ data = DEFAULT_DATA, isLoading }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [updateUser, { isSuccess }] = useUpdateUserMutation();

  useEffect(() => {
    if (isSuccess) navigate(ROUTES.ADMIN_CONSOLE.USERS.LIST);
  }, [isSuccess, navigate]);

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } =
    useFormik<UserInitialValues>({
      enableReinitialize: true,
      validateOnChange: true,
      initialValues: {
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        email: data.email || '',
        role: data.role || ''
      },
      validationSchema: rules,
      onSubmit: async (values, { setSubmitting }) => {
        try {
          if (data.email !== values.email) {
            const { message } = await updateUser({ ...values, id: data.id }).unwrap();

            enqueueSnackbar(message, {
              variant: 'success'
            });
          } else {
            const { message } = await updateUser({
              firstName: values.firstName,
              lastName: values.lastName,
              role: values.role,
              id: data.id
            }).unwrap();

            enqueueSnackbar(message, {
              variant: 'success'
            });
          }

          setSubmitting(false);
        } catch (err: any) {
          console.error('Update user: ', err);

          enqueueSnackbar(err.data.message, {
            variant: 'error'
          });
        }
      }
    });

  return (
    <StateWrapper isLoading={isLoading}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3} paddingTop="20px">
          <Grid item xs={12} sm={12} md={8} sx={{ padding: 4 }}>
            <Card sx={{ p: 3, bgcolor: 'background.paper' }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="First name"
                    {...getFieldProps('firstName')}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                  <TextField
                    fullWidth
                    label="Last name"
                    {...getFieldProps('lastName')}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Email"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <Autocomplete
                    autoComplete={false}
                    fullWidth
                    options={ROLES}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={values.role}
                    getOptionLabel={(option: any): string => option}
                    onChange={(event: SyntheticEvent, newValue) => {
                      setFieldValue('role', newValue);
                    }}
                    renderInput={(params): JSX.Element => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Role"
                        error={Boolean(touched.role && errors.role)}
                        helperText={touched.role && errors.role}
                      />
                    )}
                  />
                </Stack>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    value="save"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </form>
    </StateWrapper>
  );
};
