import styled from 'styled-components/macro';
import { Box, Typography } from '@mui/material';

export const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  width: 'calc(100% - 316px)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '20px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    justifyContent: 'center',
    paddingLeft: 0
  }
}));

export const ListWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  position: 'relative',
  flexDirection: 'row',
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center'
  }
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  color: 'rgba(0,0,0,0.8)'
}));
