import React from 'react';
import { Box } from '@mui/material';
import { RootStyle, Image, Title, Label, TextInfo } from './MainDetail.styled';
import { DashboardItem } from '../../../types/dashboard';
import { fDetails } from '../../../utils/dateHelpers';

type MainDetailProps = Pick<
  DashboardItem,
  'brand' | 'brandGroup' | 'launchDate' | 'brandPicture' | 'SKU'
>;

const TBC = 'tbc';

export const MainDetail: React.FC<MainDetailProps> = ({
  brand,
  brandGroup,
  launchDate,
  brandPicture,
  SKU
}): JSX.Element => {
  return (
    <RootStyle>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          minWidth: '100%',
          height: '198px',
          background: '#FFF',
          borderRadius: '8px 8px 0 0'
        }}
      >
        <Image src={brandPicture || undefined} alt="brand picture" />
      </Box>
      <Box pl={5} pb={6}>
        <Title>{brand}</Title>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box flexDirection="column">
            <Label>Product name</Label>
            <TextInfo>{brandGroup}</TextInfo>
          </Box>
          <Box flexDirection="column" pr={5}>
            <Label>Date of Launch</Label>
            <TextInfo>{launchDate ? fDetails(launchDate) : TBC}</TextInfo>
          </Box>
        </Box>
        <Box pt={3} flexDirection="column">
          <Label>SKU</Label>
          <TextInfo>{SKU}</TextInfo>
        </Box>
      </Box>
    </RootStyle>
  );
};
