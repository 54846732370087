import * as React from 'react';
import styled from 'styled-components/macro';
import { Link, useParams } from 'react-router-dom';
import { spacing } from '@mui/system';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Box,
  Link as MuiLink,
  Container
} from '@mui/material';
import { useFetchUserQuery } from '../../../../services/UserManagementService';
import { ROUTES } from '../../../../constants/routes';
import { UserForm } from './UserForm';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const EditUser: React.FC = () => {
  const { id = '' } = useParams();

  const { data, isLoading } = useFetchUserQuery({ id }, { refetchOnMountOrArgChange: true });

  return (
    <Container maxWidth="lg" disableGutters sx={{ padding: '30px 24px 0 20px', height: '100%' }}>
      <Box sx={{ mb: 5 }}>
        <Typography variant="h3" gutterBottom>
          Edit User
        </Typography>
        <Breadcrumbs>
          <MuiLink component={Link} to={ROUTES.ADMIN_CONSOLE.ROOT}>
            Dashboard admin
          </MuiLink>
          <MuiLink component={Link} to={ROUTES.ADMIN_CONSOLE.USERS.LIST}>
            Users list
          </MuiLink>
          <Typography>Edit user</Typography>
        </Breadcrumbs>
        <UserForm data={data} isLoading={isLoading} />
      </Box>
    </Container>
  );
};

export default EditUser;
