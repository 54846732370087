import React from 'react';
import styled from 'styled-components/macro';
import { Box, Breadcrumbs, Grid, Link as MuiLink, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../constants';
import { DefaultOptionItem } from '../../types/common';
import { ROUTES } from '../../constants/routes';

const Name = styled('span')(({ theme }) => ({
  padding: '5px 10px',
  fontFamily: 'Helvetica',
  fontSize: '20px',
  fontWeight: '700',
  textTransform: 'uppercase',
  backgroundColor: 'white',
  color: 'black',
  border: '1px solid black'
}));

type HeaderProps = {
  dashboardId: string;
  company: DefaultOptionItem;
  versionName: string;
};

const DEFAULT_COMPANY = 'DIAGEO';

export const Header: React.FC<HeaderProps> = ({
  dashboardId,
  company,
  versionName
}): JSX.Element => (
  <Box sx={{ mb: '18px' }}>
    <Grid container maxWidth="100%" spacing={2} columns={16}>
      <Grid item xs={8} sx={{ pt: 0 }}>
        <Box display="flex" alignItems="center" mb="7px">
          <Typography variant="h3" mr={4} color="black">
            Impact Dashboard
          </Typography>
          <Name>{company?.name || DEFAULT_COMPANY}</Name>
        </Box>
        <Breadcrumbs>
          <MuiLink component={Link} to={PATH_DASHBOARD.ROOT}>
            Dashboard cards
          </MuiLink>
          <MuiLink
            component={Link}
            to={`${ROUTES.DASHBOARDS.ROOT}/${dashboardId}${ROUTES.VERSIONS.ROOT}`}
          >
            Versions
          </MuiLink>
          <Typography>{`Impact Dashboard (${versionName})`}</Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
  </Box>
);
