import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControlLabel,
  Link,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { customOrange } from '../../../theme/variants';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHelper';
import { filtersSlice } from '../../../redux/slices/FiltersSlice';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { CustomCheckbox } from './CustomCheckbox';
import {
  RootStyle,
  Wrapper,
  Logo,
  Cover,
  AllSelectedLabel,
  StyledControlLabel,
  IndeterminateIcon,
  SummaryTitle,
  AllSelectedItem,
  StyledPaper,
  Scrollbar
} from './FilterBar.styled';
import { DefaultOptionItem } from '../../../types/common';
import { BrandItem } from '../../../types/dashboard';
import {
  useFetchBrandGroupsQuery,
  useFetchStagesQuery,
  useFetchTypesQuery
} from '../../../services';
import { makeStyles } from '@mui/styles';
import { StateWrapper } from '../../../components/StateWrapper';

const useStyles = makeStyles((theme) => ({
  filterOption: {
    margin: 0,
    cursor: 'pointer',
    height: '36px',
    '&:hover': {
      background: 'rgba(94, 110, 232, 0.04)',
      borderRadius: '6px',
      '& $soloButton': {
        display: 'inline-block'
      }
    }
  },
  soloButton: {
    fontSize: '13px',
    paddingLeft: '12px',
    display: 'none',
    top: '1px'
  }
}));

const SELECT_ALL = 'Select all';

export const FilterBar: React.FC = () => {
  const { pathname } = useLocation();
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const {
    brands: brandsState,
    stages: stagesState,
    types: typesState
  } = useAppSelector((state) => state.filtersSlicer);

  const { setBrands, setStages, setTypes } = filtersSlice.actions;

  const isDisabled = pathname !== ROUTES.ROOT;

  const transformedBrandsState = brandsState.map((item: string) => item.replace(/['"]+/g, ''));

  const { data: brands = [], isLoading: isLoadingBrands } = useFetchBrandGroupsQuery({});
  const { data: stages = [], isLoading: isLoadingStages } = useFetchStagesQuery({});
  const { data: types = [], isLoading: isLoadingTypes } = useFetchTypesQuery({});

  const [brandsFilter, setBrandsFilter] = useState<string[]>(transformedBrandsState);
  const [stagesFilter, setStagesFilter] = useState<number[]>(stagesState);
  const [typesFilter, setTypesFilter] = useState<number[]>(typesState);

  const getSelectedLabel = (
    filters: string[] | number[],
    options: BrandItem[] | DefaultOptionItem[]
  ) => {
    if (filters.length === options.length) {
      return SELECT_ALL;
    } else return `${filters.length ? options.length - filters.length : 'All'} selected`;
  };

  useEffect(() => {
    const brandsArray = brandsFilter.map((brand) => `"${brand}"`);

    dispatch(setBrands(brandsArray));
    dispatch(setStages(stagesFilter));
    dispatch(setTypes(typesFilter));
  }, [brandsFilter, dispatch, setBrands, setStages, setTypes, stagesFilter, typesFilter]);

  const onSelectAllBrands = useCallback(() => {
    if (!brandsFilter.length) {
      const newArray = brands.map((brand) => brand.name);
      setBrandsFilter(newArray);
    } else {
      setBrandsFilter([]);
    }
  }, [brands, brandsFilter.length]);

  const onChangeBrandSelect = useCallback(
    (name) => {
      const brandsFiltersArray: string[] = brandsFilter.slice();
      const currentItem = brandsFiltersArray.indexOf(name);

      if (currentItem !== -1) {
        brandsFiltersArray.splice(currentItem, 1);
        setBrandsFilter(brandsFiltersArray);
      } else {
        setBrandsFilter((prevState) => [...prevState, name]);
      }
    },
    [brandsFilter]
  );

  const onClickBrandSolo = useCallback(
    (name) => {
      const brandsFiltersArray: string[] = brands.map((item) => item.name);
      const currentItem = brandsFiltersArray.indexOf(name);
      brandsFiltersArray.splice(currentItem, 1);
      setBrandsFilter(brandsFiltersArray);
    },
    [brands]
  );

  const onSelectAllItems = useCallback(
    (
      filter: number[],
      setFilter: Dispatch<SetStateAction<number[]>>,
      options: DefaultOptionItem[]
    ) => {
      if (!filter.length) {
        const newArray = options.map((option) => option.id);
        setFilter(newArray);
      } else {
        setFilter([]);
      }
    },
    []
  );

  const onChangeFilterItem = useCallback(
    (filter: number[], setFilter: Dispatch<SetStateAction<number[]>>, id: number) => {
      const newArray: number[] = filter.slice();
      const currentItem = newArray.indexOf(id);

      if (currentItem !== -1) {
        newArray.splice(currentItem, 1);
        setFilter(newArray);
      } else {
        setFilter((prevState) => [...prevState, id]);
      }
    },
    []
  );

  const onClickFilterSolo = useCallback(
    (options: DefaultOptionItem[], id: number, setFilter: Dispatch<SetStateAction<number[]>>) => {
      const newArray: number[] = options.map((item) => item.id);
      const currentItem = newArray.indexOf(id);
      newArray.splice(currentItem, 1);
      setFilter(newArray);
    },
    []
  );

  const isLoadingData = isLoadingBrands && isLoadingStages && isLoadingTypes;

  return (
    <RootStyle>
      <StyledPaper>
        <Wrapper>
          {isDisabled && <Cover />}
          <Logo>
            <Typography variant="h2">DIAGEO</Typography>
          </Logo>
          <Scrollbar sx={{ overflowY: isDisabled ? 'hidden' : 'scroll' }}>
            <StateWrapper isLoading={isLoadingData}>
              <Accordion defaultExpanded disableGutters sx={{ width: '100%' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: customOrange[800] }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <SummaryTitle>Brand</SummaryTitle>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ display: 'flex', flexDirection: 'column', padding: '5px 0 0' }}
                >
                  <AllSelectedItem>
                    <FormControlLabel
                      label={
                        <AllSelectedLabel>
                          {getSelectedLabel(brandsFilter, brands)}
                        </AllSelectedLabel>
                      }
                      sx={{ margin: 0 }}
                      control={
                        <CustomCheckbox
                          checked={!brandsFilter.length}
                          indeterminate={
                            brandsFilter.length > 0 && brandsFilter.length !== brands.length
                          }
                          indeterminateIcon={<IndeterminateIcon />}
                          onChange={onSelectAllBrands}
                        />
                      }
                    />
                    {brandsFilter.length !== brands.length && (
                      <Link
                        component="button"
                        variant="body2"
                        underline="always"
                        sx={{ fontSize: '12px', paddingLeft: '12px' }}
                        onClick={() => {
                          setBrandsFilter(brands.map((item) => item.name));
                        }}
                      >
                        Clear all
                      </Link>
                    )}
                  </AllSelectedItem>
                  {brands.map((brand: DefaultOptionItem, index) => (
                    <Box key={index} className={classes.filterOption}>
                      <StyledControlLabel
                        key={brand.name}
                        label={brand.name}
                        control={
                          <CustomCheckbox
                            checked={!brandsFilter.includes(brand.name)}
                            onChange={() => onChangeBrandSelect(brand.name)}
                          />
                        }
                      />
                      <Link
                        component="button"
                        variant="body2"
                        underline="hover"
                        className={classes.soloButton}
                        onClick={() => onClickBrandSolo(brand.name)}
                      >
                        Solo
                      </Link>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Divider sx={{ margin: '8px 0 16px 0' }} />
              <Accordion defaultExpanded disableGutters sx={{ width: '100%' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: customOrange[800] }} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <SummaryTitle>Design stage</SummaryTitle>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ display: 'flex', flexDirection: 'column', padding: '5px 0 0' }}
                >
                  <AllSelectedItem>
                    <FormControlLabel
                      label={
                        <AllSelectedLabel>
                          {getSelectedLabel(stagesFilter, stages)}
                        </AllSelectedLabel>
                      }
                      sx={{ margin: 0 }}
                      control={
                        <CustomCheckbox
                          checked={!stagesFilter.length}
                          indeterminate={
                            stagesFilter.length > 0 && stagesFilter.length !== stages.length
                          }
                          indeterminateIcon={<IndeterminateIcon />}
                          onChange={() => onSelectAllItems(stagesFilter, setStagesFilter, stages)}
                        />
                      }
                    />
                    {stagesFilter.length !== stages.length && (
                      <Link
                        component="button"
                        variant="body2"
                        underline="always"
                        sx={{ fontSize: '12px', paddingLeft: '12px' }}
                        onClick={() => {
                          setStagesFilter(stages.map((item) => item.id));
                        }}
                      >
                        Clear all
                      </Link>
                    )}
                  </AllSelectedItem>
                  {stages.map((stage: DefaultOptionItem, index) => (
                    <Box key={index} className={classes.filterOption}>
                      <StyledControlLabel
                        key={stage.name}
                        label={stage.name}
                        control={
                          <CustomCheckbox
                            checked={!stagesFilter.includes(stage.id)}
                            onChange={() =>
                              onChangeFilterItem(stagesFilter, setStagesFilter, stage.id)
                            }
                          />
                        }
                      />
                      <Link
                        component="button"
                        variant="body2"
                        underline="hover"
                        className={classes.soloButton}
                        onClick={() => onClickFilterSolo(stages, stage.id, setStagesFilter)}
                      >
                        Solo
                      </Link>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Divider sx={{ margin: '8px 0 16px 0' }} />
              <Accordion defaultExpanded disableGutters sx={{ width: '100%' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: customOrange[800] }} />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <SummaryTitle>Type</SummaryTitle>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ display: 'flex', flexDirection: 'column', padding: '5px 0 0' }}
                >
                  <AllSelectedItem>
                    <FormControlLabel
                      label={
                        <AllSelectedLabel>{getSelectedLabel(typesFilter, types)}</AllSelectedLabel>
                      }
                      sx={{ margin: 0 }}
                      control={
                        <CustomCheckbox
                          checked={!typesFilter.length}
                          indeterminate={
                            typesFilter.length > 0 && typesFilter.length !== types.length
                          }
                          indeterminateIcon={<IndeterminateIcon />}
                          onChange={() => onSelectAllItems(typesFilter, setTypesFilter, types)}
                        />
                      }
                    />
                    {typesFilter.length !== types.length && (
                      <Link
                        component="button"
                        variant="body2"
                        underline="always"
                        sx={{ fontSize: '12px', paddingLeft: '12px' }}
                        onClick={() => {
                          setTypesFilter(types.map((item) => item.id));
                        }}
                      >
                        Clear all
                      </Link>
                    )}
                  </AllSelectedItem>
                  {types.map((type: DefaultOptionItem, index) => (
                    <Box key={index} className={classes.filterOption}>
                      <StyledControlLabel
                        key={type.name}
                        label={type.name}
                        control={
                          <CustomCheckbox
                            checked={!typesFilter.includes(type.id)}
                            onChange={() =>
                              onChangeFilterItem(typesFilter, setTypesFilter, type.id)
                            }
                          />
                        }
                      />
                      <Link
                        component="button"
                        variant="body2"
                        underline="hover"
                        className={classes.soloButton}
                        onClick={() => onClickFilterSolo(types, type.id, setTypesFilter)}
                      >
                        Solo
                      </Link>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
              <Divider sx={{ marginTop: '8px' }} />
            </StateWrapper>
          </Scrollbar>
        </Wrapper>
      </StyledPaper>
    </RootStyle>
  );
};
