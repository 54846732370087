import React from 'react';
import { Box, BoxProps } from '@mui/material';

export const LogoIcon: React.FC = ({ ...other }: BoxProps) => {
  return (
    <Box {...other}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4188 20.461C14.6713 21.5546 13.4717 22.3856 12.5385 21.8023L9.35599 19.8136L5.38201 26.1731C4.34419 27.8337 5.54372 29.9999 7.50091 29.9999H11.5625C12.0802 29.9999 12.5 30.4196 12.5 30.9374V34.0624C12.5 34.5801 12.0802 34.9999 11.5625 34.9999H7.50896C1.62349 34.9999 -1.96776 28.5117 1.14896 23.5249L5.11943 17.1664L1.93779 15.1783C0.993959 14.5887 1.23732 13.1501 2.31896 12.9003L10.9312 10.912C11.604 10.7567 12.2751 11.1762 12.4304 11.8488L14.4188 20.461ZM22.1189 6.1708L25.3446 11.333L22.1618 13.3218C21.2203 13.9099 21.4586 15.3494 22.5429 15.5998L31.1552 17.5881C31.8327 17.7442 32.5001 17.3195 32.6543 16.6513L34.6426 8.03908C34.8924 6.95752 33.7059 6.10791 32.7623 6.69783L29.5836 8.68408L26.36 3.52142C23.4286 -1.16866 16.578 -1.17944 13.64 3.52142L12.2366 5.76876C11.9624 6.20783 12.096 6.78603 12.5351 7.06041L15.185 8.71627C15.624 8.99064 16.2025 8.85712 16.4768 8.41806L17.8801 6.17251C18.8732 4.58337 21.1592 4.63533 22.1189 6.1708ZM38.8506 23.5249L36.701 20.0823C36.4267 19.643 35.8482 19.5093 35.409 19.7838L32.7636 21.4368C32.3245 21.7112 32.1909 22.2895 32.4653 22.7287L34.6187 26.1749C35.6544 27.832 34.458 29.9999 32.4987 29.9999H25V26.2514C25 25.141 23.6529 24.5809 22.8661 25.3676L16.6161 31.6161C16.1279 32.1042 16.1279 32.8957 16.6161 33.3839L22.8661 39.633C23.6475 40.4146 25 39.8671 25 38.749V34.9999H32.4906C38.3714 34.9999 41.9699 28.5157 38.8506 23.5249Z"
          fill="#2DB744"
        />
      </svg>
    </Box>
  );
};
