import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  TableCell,
  TableRow
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import React from 'react';
import { User } from '../../../../types/user';

interface UserItemProps {
  item: User;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  isOpen: boolean;
  itemId: number | null;
  onOpen: (id: number) => void;
  onClose: () => void;
}

export const UserItem: React.FC<UserItemProps> = ({
  item,
  onEdit,
  onDelete,
  isOpen,
  itemId,
  onOpen,
  onClose
}) => {
  return (
    <TableRow key={`${item?.id}---`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell align="center">{item?.email}</TableCell>
      <TableCell align="center">{item?.firstName}</TableCell>
      <TableCell align="center">{item?.lastName}</TableCell>
      <TableCell align="center">{item?.role}</TableCell>
      <TableCell>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Box>
            <IconButton
              aria-label="Edit"
              size="large"
              title="Edit dashboard "
              onClick={() => onEdit(item?.id)}
            >
              <EditIcon />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              aria-label="Delete"
              size="large"
              title="Delete dashboard"
              onClick={() => onOpen(item.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Stack>
      </TableCell>
      <Dialog open={item.id === itemId && isOpen} onClose={onClose} aria-labelledby="dialog-title">
        <DialogTitle id="dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => onDelete(item.id)}>Delete</Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
};
