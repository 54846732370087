import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_ROUTES } from '../constants/api.routes';

export const companyAPI = createApi({
  reducerPath: 'companyAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('accessToken');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['Companies'],
  endpoints: (build) => ({
    fetchCompanies: build.query<any, {}>({
      query: () => ({
        url: API_ROUTES.COMPANY.ROOT
      }),
      providesTags: () => ['Companies']
    })
  })
});
export const { useFetchCompaniesQuery } = companyAPI;
