import styled from 'styled-components/macro';
import { Box, FormControlLabel, Paper, Typography } from '@mui/material';
import { customOrange } from '../../../theme/variants';

export const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  padding: '20px 20px 17px'
}));

export const Logo = styled(Box)(({ theme }) => ({
  border: '1px solid #000',
  borderRadius: '8px',
  width: '143px',
  height: '47px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '17px'
}));

export const Cover = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#F0F2F7',
  borderRadius: '8px',
  opacity: 0.7,
  zIndex: 10,
  transition: 'opacity 1s linear'
}));

export const RootStyle = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 184px)',
  maxHeight: '994px',
  width: '316px',
  minWidth: '280px',
  borderRadius: '8px'
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  height: 'calc(100vh - 184px)',
  maxHeight: '994px',
  minWidth: '280px',
  width: '316px',
  background: '#FFFFFF',
  borderRadius: '8px',
  position: 'fixed',
  [theme.breakpoints.down('md')]: {
    height: '100%'
  }
}));

export const Scrollbar = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'calc(100% - 64px)',
  '::-webkit-scrollbar-track': { borderRadius: '8px' },
  '::-webkit-scrollbar': { width: '6px' },
  '::-webkit-scrollbar-thumb': { borderRadius: '8px', background: customOrange[600] },
  '&:hover::-webkit-scrollbar-thumb': { background: customOrange[700] }
}));

export const StyledControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  height: '36px'
}));

export const AllSelectedLabel = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.6)',
  fontFamily: 'Helvetica',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '14px'
}));

export const AllSelectedItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '36px'
}));

export const SummaryTitle = styled(Typography)(({ theme }) => ({
  color: customOrange[800],
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '16px'
}));

export const Icon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 15,
  height: 15,
  background: '#FFFFFF',
  border: '1px solid #C3C3C3',
  'input:hover ~ &': {
    border: '1px solid #5E6EE8'
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)'
  }
}));

export const CheckedIcon = styled(Icon)({
  backgroundColor: '#5E6EE8',
  borderColor: '#5E6EE8',
  backgroundImage: `url('data:image/svg+xml;utf8,<svg width="13" height="14" viewBox="-1 0.5 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.331137 4.2987C0.111759 4.07798 0.111759 3.7199 0.331315 3.49901C0.550693 3.27793 0.906426 3.27811 1.12598 3.49919L4.10753 6.4986L9.73936 0.832431C9.95891 0.611358 10.3145 0.611358 10.5342 0.832431C10.7534 1.05297 10.7536 1.41105 10.5342 1.63194L4.459 7.66663C4.23998 7.88734 3.88407 7.88734 3.66451 7.66663C3.59778 7.59948 3.58253 7.5848 3.52521 7.51207L0.331137 4.2987Z" fill="white"/>
</svg>
')`
});

export const IndeterminateIcon = styled(Icon)({
  backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 29 28"><path fill="%235E6EE8" d="M19 13H5a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2Z"/></svg>
')`
});
