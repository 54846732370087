const ADMIN_ROOT = '/admin';
const DASHBOARDS_ROOT = '/admin/dashboards';
const USERS_ROOT = '/admin/users';

export const ROUTES = {
  ROOT: '/',
  DASHBOARDS: {
    ROOT: '/dashboards'
  },
  VERSIONS: {
    ROOT: '/versions',
    EDIT: '/edit'
  },
  ADMIN_CONSOLE: {
    ROOT: ADMIN_ROOT,
    DASHBOARDS: {
      ROOT: DASHBOARDS_ROOT,
      LIST: `${DASHBOARDS_ROOT}/list`,
      ADD: `${DASHBOARDS_ROOT}/add`,
      EDIT: `/edit`
    },
    USERS: {
      ROOT: USERS_ROOT,
      LIST: `${USERS_ROOT}/list`,
      INVITE: `${USERS_ROOT}/invite`,
      EDIT: '/edit'
    }
  }
};
