import styled from 'styled-components/macro';
import { Box, Typography } from '@mui/material';

export const RootStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxWidth: '292px',
  height: 'auto',
  marginBottom: '25px'
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: '16px',
  marginTop: '10px',
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '16px',
  fontFamily: 'Helvetica',
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.8)'
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  fontFamily: 'Helvetica',
  fontStyle: 'normal',
  maxWidth: '165px',
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.7)',
  lineHeight: '13px'
}));

export const ChartInnerText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  color: 'black',
  fontSize: '17px',
  fontFamily: 'Helvetica',
  fontWeight: 700,
  transform: 'translate(100%, 160%)'
}));
