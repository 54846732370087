import { Checkbox, CheckboxProps } from '@mui/material';
import { Icon, CheckedIcon } from './FilterBar.styled';

export const CustomCheckbox = (props: CheckboxProps) => {
  return (
    <Checkbox
      icon={<Icon />}
      checkedIcon={<CheckedIcon />}
      sx={{
        padding: '8px',
        '&:hover': { bgcolor: 'transparent' }
      }}
      {...props}
    />
  );
};
