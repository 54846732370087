import { format, isValid } from 'date-fns';

export const fIso8601 = (date: Date | string | number): string =>
  format(new Date(date), 'yyyy-MM-dd');

export const formatPickerValue = (e: string | Date | null): string | null => (e ? fIso8601(e) : e);

export const isValidDate = (date: string | Date | null): boolean => isValid(date);

export const fCard = (date: Date | string | number): string => format(new Date(date), 'do MMMM y');

export const fDetails = (date: Date | string | number): string =>
  format(new Date(date), 'MM/dd/yy');
